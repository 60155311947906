import { useLandingTracking } from 'shared/hooks'
import { SignUpForm } from 'authentication/components'

interface Props {
  redirectUrl: string
}

const SignUpStep = ({ redirectUrl }: Props) => {
  const { isSdrFlow, getLandingLocation } = useLandingTracking()

  return (
    <>
      <SignUpForm redirectUrl={isSdrFlow ? getLandingLocation() : redirectUrl} />
    </>
  )
}

export { SignUpStep }
