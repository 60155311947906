import classNames from 'classnames'

export const SidePanel = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <aside className={classNames('w-full lg:w-3/5 bg-neutral-darkest', className)}>
      {children}
    </aside>
  )
}
