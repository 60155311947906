import { CheckboxGroupOption } from 'shared/components/atoms'
import { FormControl } from 'shared/hooks'
import { matchingEventFilterLabels, MatchingEventFilter } from 'candidates-search/enums'

import { defaultMatchingEvents } from '../../schema'
import { Filter } from '../Filter'

interface MatchingEventsFilterProps {
  formControl: FormControl
  disabled: boolean
}

export const MatchingEventsFilter = ({ formControl, disabled }: MatchingEventsFilterProps) => {
  const { setValue } = formControl

  const handleClear = () => {
    setValue('matchingEvents', defaultMatchingEvents)
  }

  const options: Array<CheckboxGroupOption> = [
    {
      label: matchingEventFilterLabels[MatchingEventFilter.MatchingUpdates],
      name: MatchingEventFilter.MatchingUpdates,
    },
    {
      label: matchingEventFilterLabels[MatchingEventFilter.ScreeningProgress],
      name: MatchingEventFilter.ScreeningProgress,
    },
    {
      label: matchingEventFilterLabels[MatchingEventFilter.CommunicationSent],
      name: MatchingEventFilter.CommunicationSent,
    },
  ]

  return (
    <Filter
      text="Matching"
      formControl={formControl}
      name="matchingEvents"
      options={options}
      onClear={handleClear}
      disabled={disabled}
    />
  )
}
