import { Tooltip, Text } from 'shared/components/atoms'
import { JobOpportunityStatus } from 'job-opportunity/enums'
import { cn } from 'modules/shared'
import { JobOpportunity } from 'job-opportunity/domain'

interface Props {
  jobOpportunity: JobOpportunity
  pendingTasks: boolean
}

export const JobOpportunityBadge = ({ jobOpportunity, pendingTasks }: Props) => {
  const { status, isOpen } = jobOpportunity
  const showPendingTasks = pendingTasks && isOpen

  const baseClasses = 'flex w-fit justify-center items-center px-2 py-1 rounded inline-block'

  const defaultBadge = {
    background: 'bg-neutral-lightest',
    text: 'text-neutral-dark',
    label: 'Not available',
  }

  const pendingTaskBadge = {
    background: 'bg-warning-lighter',
    text: 'text-warning-dark',
    label: 'On hold',
  }

  const jobOpportunityBadges = {
    [JobOpportunityStatus.Pending]: {
      background: 'bg-neutral-lightest',
      text: 'text-neutral-dark',
      label: 'Pending',
    },
    [JobOpportunityStatus.AwaitingReview]: {
      background: 'bg-warning-lighter',
      text: 'text-warning-dark',
      label: 'Awaiting review',
    },
    [JobOpportunityStatus.NotInterested]: {
      background: 'bg-neutral-lightest',
      text: 'text-neutral-dark',
      label: 'Not Interested',
    },
    [JobOpportunityStatus.NotPicked]: {
      background: 'bg-neutral-lightest',
      text: 'text-neutral-dark',
      label: 'Not picked',
    },
    [JobOpportunityStatus.InProgress]: {
      background: 'bg-info-lighter',
      text: 'text-info-dark',
      label: 'In Progress',
    },
    [JobOpportunityStatus.Expired]: {
      background: 'bg-neutral-lightest',
      text: 'text-neutral-dark',
      label: 'Expired',
    },
    [JobOpportunityStatus.Closed]: {
      background: 'bg-neutral-lightest',
      text: 'text-neutral-dark',
      label: 'Closed',
    },
    [JobOpportunityStatus.Received]: {
      background: 'bg-info-lighter',
      text: 'text-info-dark',
      label: 'Received',
    },
  }

  const badge = showPendingTasks ? pendingTaskBadge : jobOpportunityBadges[status] || defaultBadge

  return (
    <Tooltip content="Status">
      <Text
        weight="font-medium"
        size="text-xs"
        className={cn(baseClasses, badge.background, badge.text, 'whitespace-nowrap')}
      >
        {badge.label}
      </Text>
    </Tooltip>
  )
}
