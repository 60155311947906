import { Carousel } from 'shared/components/atoms'
import { Roles } from 'account/enums'
import { ReactComponent as Logo } from 'icons/brand/brand-colored-positive.svg'
import { Description } from './Description'

interface Props {
  accountRole?: Roles
}

const SidePanel = ({ accountRole = Roles.EmployerOwner }: Props) => {
  const jobSeekerItems = [
    {
      path: 'images/auth-position.png',
      title: 'Join and the jobs come to you',
      description:
        "Sign up, build your profile, and select your interests. You're just a few steps away from employers requesting interviews with you.",
    },
    {
      path: 'images/auth-interview.png',
      title: 'Top US companies hiring',
      description:
        'We only work with top US companies who pay well and provide opportunities for career advancement.',
    },
    {
      path: 'images/auth-person.png',
      title: "We'll help you land your dream job",
      description:
        "You'll get a help for our Talent Agents to help you prepare for the interviews and land your dream job.",
    },
  ]

  return (
    <aside className="w-full lg:h-screen flex flex-col items-center justify-center lg:w-1/2 py-6 lg:py-0 bg-neutral-lightest sticky top-0 left-0 z-20">
      <Logo className="h-7 w-auto lg:hidden" />

      {accountRole === Roles.JobSeeker ? (
        <Carousel className="hidden lg:flex">
          {jobSeekerItems.map(({ description, path, title }, index) => (
            <Description
              key={`carousel-${index}`}
              path={path}
              description={description}
              title={title}
            />
          ))}
        </Carousel>
      ) : (
        <Description
          className="hidden lg:flex"
          description="Our hiring platform is an end-to-end solution that connects vetted, remote developers to opportunities at US companies."
          path="images/diverse-people.png"
          title="Connecting exceptional developers with top companies"
        />
      )}
    </aside>
  )
}

export { SidePanel }
