import { Link } from 'react-router-dom'

import { Text } from 'shared/components/atoms'
import { IconBanner } from 'shared/components/molecules'

import { ReactComponent as AlertIcon } from 'icons/alert-triangle--filled.svg'

import { usePendingTasks } from '../../hooks'

import { JobOpportunity } from 'job-opportunity/domain'
import { VettingPackageResponse } from 'vetting/domain'

interface JobOpportunityOnHoldProps {
  appliedJobOpportunities: Array<JobOpportunity>
  vetting?: VettingPackageResponse
}

export const JobOpportunityOnHold = ({
  appliedJobOpportunities,
  vetting,
}: JobOpportunityOnHoldProps) => {
  const { holdJobOpportunityTasks } = usePendingTasks(vetting)

  if (!appliedJobOpportunities.length || !holdJobOpportunityTasks.length) return null

  const multipleJobOpportunities = appliedJobOpportunities.length > 1

  return (
    <IconBanner
      icon={<AlertIcon className="w-6 h-6 fill-warning-dark" />}
      bgColor="bg-warning-lighter"
      border="border-warning-light"
    >
      <Text>
        You have{' '}
        {multipleJobOpportunities
          ? `${appliedJobOpportunities.length} job opportunities`
          : 'a job opportunity'}{' '}
        on hold due to pending English Check. To proceed,{' '}
        <Link to="/english-vetting">record English Check</Link>.
      </Text>
    </IconBanner>
  )
}
