import { useNavigate } from 'react-router-dom'
import { AlertDialog } from 'shared/components/molecules'
import { Paragraph } from 'shared/components/atoms'
import { PrimaryButton, SecondaryButton } from 'shared/components/atoms'
import { Title } from 'shared/components/atoms'

import { ReactComponent as CheckIcon } from 'icons/check.svg'

import { VettingPackageResponse } from 'vetting/domain'
import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { useLandingTracking } from 'shared/hooks'

interface ExternalApplicationModalProps {
  open: boolean
  onCancel: () => void
  profile: ProfileOverview
  vetting: VettingPackageResponse | undefined
}

export const ExternalApplicationModal = ({
  open,
  onCancel,
  profile,
  vetting,
}: ExternalApplicationModalProps) => {
  const navigate = useNavigate()
  const { resetLandingLocation } = useLandingTracking()

  const isProfileIncomplete = profile.fulfillment.percentage !== 100
  const isVettingIncomplete = !vetting
  const showPrimaryButton = isProfileIncomplete || isVettingIncomplete

  const buildCopy = () => {
    if (isProfileIncomplete)
      return 'We will analyze your profile, and if you fit this position, we will contact you. To increase your chances of getting matched with the company, complete your profile and submit your English check videos.'
    if (isVettingIncomplete)
      return 'We will analyze your profile, and if you fit this position, we will contact you. To increase your chances of getting matched with the company, submit your English check videos.'
    return 'We will analyze your profile, and if you fit this position, we will contact you. '
  }

  const buildPrimaryButtonCopy = () => {
    if (isProfileIncomplete) return 'Complete profile'
    if (isVettingIncomplete) return 'Go to English check'
    return null
  }

  const buildSecondaryButtonCopy = () => {
    if (isProfileIncomplete || isVettingIncomplete) return "I'll do it later"
    return 'Got it'
  }

  const handlePrimaryButtonClick = () => {
    resetLandingLocation()
    if (isProfileIncomplete) return navigate('/profile')
    if (isVettingIncomplete) return navigate('/english-vetting')
  }

  const handleCancel = () => {
    resetLandingLocation()
    onCancel()
  }

  return (
    <AlertDialog
      isOpen={open}
      description={
        <div className="flex flex-col justify-center items-center gap-4">
          <div className="w-[120px] h-[120px] flex items-center justify-center bg-success-lighter bg-opacity-[48%] rounded-full">
            <div className="w-[88px] h-[88px] flex items-center justify-center bg-success-lighter rounded-full">
              <CheckIcon className="stroke-success-medium h-10 w-10" />
            </div>
          </div>
          <Title size="heading" color="text-neutral-darkest">
            Thank you for submitting your application
          </Title>
          <Paragraph size="body-md" weight="font-normal" className="text-neutral-dark text-center">
            {buildCopy()}
          </Paragraph>
        </div>
      }
      customActions={
        <div className="w-full flex flex-row items-center justify-center gap-4 mb-8 -mt-2">
          {showPrimaryButton && (
            <PrimaryButton onClick={handlePrimaryButtonClick}>
              {buildPrimaryButtonCopy()}
            </PrimaryButton>
          )}
          <SecondaryButton onClick={handleCancel}>{buildSecondaryButtonCopy()}</SecondaryButton>
        </div>
      }
      isCancellable={false}
    />
  )
}
