import {
  CheckboxGroup,
  CheckboxGroupOption,
  CountBadge,
  DropdownWrapper,
  TertiaryButton,
  Text,
} from 'shared/components/atoms'
import { FormControl } from 'shared/hooks'

interface FilterProps {
  text: string
  formControl: FormControl
  name: string
  options: Array<CheckboxGroupOption>
  disabled: boolean
  onClear: () => void
}

export const Filter = ({ text, formControl, name, options, disabled, onClear }: FilterProps) => {
  const { register, watch } = formControl

  const selectedOptions = watch(name) as Record<string, boolean>
  const selectedCount = Object.values(selectedOptions).filter((value) => value).length

  return (
    <DropdownWrapper
      text={text}
      triggerContent={
        selectedCount ? (
          <CountBadge value={selectedCount} className="w-5 flex items-center justify-center" />
        ) : (
          <span className="flex justify-center items-center bg-neutral-lightest w-5 h-5 rounded whitespace-nowrap">
            <Text size="text-sm" className="text-neutral-dark">
              {disabled ? '-' : 'All'}
            </Text>
          </span>
        )
      }
      contentClassName="p-0"
      disabled={disabled}
    >
      <div className="py-2 px-4 max-h-52 overflow-y-auto">
        <CheckboxGroup name={name} register={register} options={options} />
      </div>
      <div className="border-t border-neutral-lightest">
        <TertiaryButton className="w-full justify-start" onClick={onClear}>
          Clear filter
        </TertiaryButton>
      </div>
    </DropdownWrapper>
  )
}
