import { textStyles } from 'shared/components/atoms'
import { ReactNode } from 'react'
import { cn } from 'modules/shared'

export interface LabelProps {
  id?: string
  htmlFor: string
  children?: ReactNode
  weight?: 'font-normal' | 'font-medium'
  className?: string
  size?: 'text-base' | 'text-xs' | 'text-sm'
  cursor?: 'cursor-pointer'
  isOptional?: boolean
  title?: string
  onClick?: () => void
}

export const Label = ({
  id,
  htmlFor,
  children,
  weight = 'font-medium',
  className,
  size = 'text-sm',
  cursor,
  isOptional,
  title,
  onClick,
}: LabelProps) => {
  const classes = cn('block mb-2 cursor-pointer', textStyles(size, weight), className, cursor)

  return (
    <label id={id} htmlFor={htmlFor} className={classes} title={title} onClick={onClick}>
      {children}
      {isOptional && (
        <span className="ml-4 text-inherit font-inherit !font-normal !text-xs text-neutral-medium">
          optional
        </span>
      )}
    </label>
  )
}
