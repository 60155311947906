import { useState } from 'react'

const REFERRAL_PAGE_SEEN_KEY = 'referral_page_seen'

export const useReferralPageSeen = () => {
  const initialState = JSON.parse(localStorage.getItem(REFERRAL_PAGE_SEEN_KEY) || 'false')
  const [isSeen, setIsSeen] = useState(initialState)

  const setSeen = () => {
    localStorage.setItem(REFERRAL_PAGE_SEEN_KEY, 'true')
    setIsSeen(true)
  }

  return { isSeen, setSeen }
}
