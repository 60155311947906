import { TrackingEvent } from '../tracking-event'

export class ProfileView implements TrackingEvent {
  name = 'Profile view'

  constructor(
    private id: string,
    private primaryRole: string | undefined,
  ) {}

  get payload() {
    return { primaryRole: this.primaryRole || 'N/A', id: this.id }
  }
}
