export enum EnglishVettingStep {
  HardwareCheckup = 'hardware-checkup',
  AboutCandidate = 'about-candidate',
  TechnicalChallenges = 'technical-challenges',
  FutureInterviews = 'future-interviews',
  Submit = 'submit',
}

export type RecordingStep =
  | EnglishVettingStep.AboutCandidate
  | EnglishVettingStep.TechnicalChallenges
  | EnglishVettingStep.FutureInterviews
