import { usePageTitle } from 'shared/hooks'
import { useMemo } from 'react'
import { ProfileOverview } from '../types'
import { useSensitiveInformation } from 'shared/components/atoms'

export const useCandidatePageTitle = (profile: ProfileOverview) => {
  const { blurSensitiveInfo } = useSensitiveInformation()

  const title = useMemo(() => {
    const { id, firstName, lastName, primaryRole } = profile

    if (!id || blurSensitiveInfo) {
      return null
    }

    if (primaryRole) {
      return `${firstName} ${lastName} - ${primaryRole.label}`
    }

    return `${firstName} ${lastName}`
  }, [profile])

  usePageTitle(title)
}
