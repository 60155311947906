import { useContext } from 'react'
import { useQuery } from 'react-query'
import { plainToInstance } from 'class-transformer'

import { useMonitoring, useNetwork, useToast } from 'shared/hooks'

import { CandidateContext } from 'contexts/candidate'

import { JobOpportunity } from 'job-opportunity/domain'

export const useFetchJobOpportunity = (jobOpportunityId: string) => {
  const { toastError } = useToast()
  const { candidate } = useContext(CandidateContext)
  const { get } = useNetwork()
  const { captureException } = useMonitoring()

  const fetchJobOpportunity = () =>
    get<JobOpportunity>(`profile/${candidate.id}/job-opportunities/${jobOpportunityId}`).then(
      ({ data }) => plainToInstance(JobOpportunity, data),
    )

  const {
    data: jobOpportunity,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(['job-opportunity', jobOpportunityId], fetchJobOpportunity, {
    onError: (error) => {
      toastError('Failed to fetch the job opportunity.')
      captureException(error)
    },
    staleTime: 5 * 60 * 1000,
    enabled: Boolean(jobOpportunityId),
    retry: (_, error: RequestError) => ![404, 403].includes(Number(error.response?.status)),
  })

  return { jobOpportunity, isLoading, isRefetching, fetchJobOpportunity, refetch }
}
