import {
  ReactNode,
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
} from 'react'

const STORAGE_KEY = 'str.blurred-mode'

const SensitiveInformationContext = createContext({
  blurSensitiveInfo: false,
  setBlurSensitiveInfo: (() => {}) as Dispatch<SetStateAction<boolean>>,
})

export const SensitiveInformationProvider = ({ children }: { children: ReactNode }) => {
  const initialValue = JSON.parse(localStorage.getItem(STORAGE_KEY) ?? 'false')
  const [blurSensitiveInfo, setBlurSensitiveInfo] = useState(initialValue)

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, String(blurSensitiveInfo))
  }, [blurSensitiveInfo])

  return (
    <SensitiveInformationContext.Provider value={{ blurSensitiveInfo, setBlurSensitiveInfo }}>
      {children}
    </SensitiveInformationContext.Provider>
  )
}

export const useSensitiveInformation = () => useContext(SensitiveInformationContext)
