import { useState } from 'react'
import classNames from 'classnames'

import { Overlay } from 'shared/components/molecules'
import { ReactComponent as CloseIcon } from 'icons/close.svg'
import { Recording } from 'vetting/domain'
import { EnglishVettingStep, RecordingStep } from 'vetting/enums'

import { Steps } from './Steps'
import { VideoPlayer } from 'vetting/components'

interface WatchRecordingsModalProps {
  open: boolean
  onClose: () => void
  recordings: Record<RecordingStep, Partial<Recording>>
  initialStep?: number
  orientation?: 'horizontal' | 'vertical'
  theme?: 'light' | 'dark'
}

const steps = [
  EnglishVettingStep.AboutCandidate,
  EnglishVettingStep.TechnicalChallenges,
  EnglishVettingStep.FutureInterviews,
] as Array<RecordingStep>

export const WatchRecordingsModal = ({
  open,
  onClose,
  recordings,
  initialStep = 0,
  orientation = 'horizontal',
  theme = 'dark',
}: WatchRecordingsModalProps) => {
  const [currentStep, setCurrentStep] = useState(initialStep)

  const handleStepClick = (step: number) => setCurrentStep(step)

  return (
    <Overlay
      open={open}
      showClose={false}
      handleClose={onClose}
      containerClassName={theme === 'dark' ? '!bg-neutral-darkest' : '!bg-neutral-day'}
      titleContainerClassName="!p-6 !justify-end border-none"
      title={
        <div
          className={classNames(
            'flex items-center justify-center w-10 h-10 hover:cursor-pointer rounded-lg',
            {
              'hover:bg-neutral-lightest': theme === 'light',
              'hover:bg-neutral-darker': theme === 'dark',
            },
          )}
          onClick={onClose}
        >
          <CloseIcon className="w-6 h-6 stroke-neutral-medium" />
        </div>
      }
      contentContainerClassName="!px-16 w-full max-w-[1440px] m-auto"
      content={
        <div
          className={classNames('h-full w-full flex items-center', {
            'flex-row gap-14 justify-between': orientation === 'horizontal',
            'flex-col-reverse gap-8 justify-center': orientation === 'vertical',
          })}
        >
          <Steps
            currentStep={currentStep}
            onStepClick={handleStepClick}
            orientation={orientation}
            theme={theme}
          />
          <VideoPlayer recording={recordings[steps[currentStep]]} className="rounded-lg" />
        </div>
      }
    />
  )
}
