export class RecruiterOutOfBounds {
  byCountry: boolean
  byTaxInformation: boolean
  byStriderStaff: boolean
}

export class Recruiter {
  id: string
  firstName: string
  lastName: string
  approved: boolean
  slug: string | null
  outOfBounds: RecruiterOutOfBounds
  jobBoardURL: string | null
}
