import classNames from 'classnames'
import { useMemo } from 'react'

import { Text } from 'shared/components/atoms'
import { SignUpPanel } from './components/SignUpPanel'
import { SidePanel } from '../../components/SidePanel'

import { ReactComponent as BlurLight } from 'icons/landing/blur-light.svg'
import { ReactComponent as CheckIcon } from 'icons/landing/check-icon.svg'
import { ReactComponent as Logo } from 'icons/brand/brand-colored-negative.svg'

const BENEFITS = [
  'Find a great full-time opportunity',
  'Earn more compensation for your hard work',
  'Access exclusive benefits like healthcare, English classes, and more',
  '1-1 individualized training to succeed in the international job market',
]

const TITLE = 'Get matched with the best remote opportunities from today’s top US companies'

export const JobSeekerLandingPage = () => {
  const redirectUrl = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const url = urlParams.get('redirectUrl')
    const inviteId = urlParams.get('inviteId')

    if (url) return inviteId ? url.concat(`&inviteId=${inviteId}`) : url
    return '/job-seeker-onboarding'
  }, [])

  return (
    <div className="flex flex-col-reverse lg:flex-row lg:h-screen lg:overflow-hidden">
      <SignUpPanel redirectUrl={redirectUrl} />
      <SidePanel className="relative px-8 pt-8 pb-16 lg:px-24 lg:pt-24 lg:pb-0 flex flex-col items-start overflow-hidden">
        <Logo className="h-7 w-auto block mb-10 lg:hidden" />
        <h1
          className={classNames(
            'text-neutral-day font-inter font-medium whitespace-pre-wrap',
            '3xl:text-[2.25rem] 3xl:leading-[3.25rem] 3xl:tracking-[-0.02em] 3xl:mb-20',
            'lg:text-[2rem] lg:leading-10 lg:tracking-[-0.02em] lg:mb-[60px]',
            'text-xl leading-[1.875rem] mb-6',
          )}
        >
          {TITLE}
        </h1>

        <ul className="lg:mb-20 flex flex-col gap-4 z-10">
          {BENEFITS.map((benefit, index) => (
            <li key={index} className="flex flex-row justify-start items-start gap-4">
              <CheckIcon className="min-w-[20px]" />
              <Text size="text-sm" weight="font-normal" className="text-neutral-day 3xl:text-base">
                {benefit}
              </Text>
            </li>
          ))}
        </ul>

        <div className="w-[calc(100%+96px)] flex-grow hidden lg:flex flex-col justify-end z-10">
          <img
            src="https://res.cloudinary.com/onstrider/image/upload/f_auto,q_auto/v1/landings/job-seeker-dashboard_o3p2j9"
            alt="Job Seeker Dashboard"
          />
        </div>

        <BlurLight className="absolute left-[-500px] lg:-bottom-[600px] lg:-left-[250px] 3xl:-bottom-[416px]" />
      </SidePanel>
    </div>
  )
}
