import React from 'react'

interface FooterProps {
  children: React.ReactNode
}
export const Footer = ({ children }: FooterProps) => {
  return (
    <div className="flex w-full gap-4 justify-end flex-col-reverse sm:flex-row">{children}</div>
  )
}
