import { Text } from 'shared/components/atoms'
import { Card } from '../Card'
import {
  AssessmentTask,
  CompleteProfileTask,
  EmptyState,
  EnglishVettingTask,
  JobOpportunityOnHoldTag,
  OfferTasks,
} from './components'

import { usePendingTasks } from '../../hooks'

import { Tasks as TasksEnum } from 'modules/candidates/enums'

import { VettingPackageResponse } from 'vetting/domain'
import { JobOpportunity } from 'job-opportunity/domain'

interface TasksCardProps {
  vetting: VettingPackageResponse | undefined
  appliedJobOpportunities: Array<JobOpportunity>
  isDeactivated: boolean
}

export const TasksCard = ({ vetting, appliedJobOpportunities, isDeactivated }: TasksCardProps) => {
  const { pendingOffers, pendingTasks, holdJobOpportunityTasks } = usePendingTasks(vetting)

  const tasksComponents = {
    [TasksEnum.CompleteProfile]: <CompleteProfileTask key="completeProfile" />,
    [TasksEnum.Assessment]: <AssessmentTask key="assessment" />,
    [TasksEnum.Offer]: <OfferTasks key="offers" offers={pendingOffers} />,
    [TasksEnum.EnglishVetting]: <EnglishVettingTask key="englishVetting" />,
    [TasksEnum.LiveInterview]: <div />,
  }

  return (
    <Card className="flex flex-col gap-10">
      <div className="flex items-center justify-between">
        <Text size="text-sm" weight="font-medium" className="hidden md:block">
          Tasks
        </Text>

        <Text size="text-xl" weight="font-medium" className="block md:hidden">
          Tasks
        </Text>

        <JobOpportunityOnHoldTag
          appliedJobOpportunities={appliedJobOpportunities}
          holdTasks={holdJobOpportunityTasks}
        />
      </div>

      <div className="flex flex-col gap-10">
        {pendingTasks.map((task) => tasksComponents[task])}
        {Boolean(!pendingTasks.length) && <EmptyState isDeactivated={isDeactivated} />}
      </div>
    </Card>
  )
}
