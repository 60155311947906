import { useNavigate } from 'react-router-dom'

import { PrimaryButton } from 'shared/components/atoms'
import { Text } from 'components/Text'

import { ReactComponent as AttentionIcon } from 'icons/attention.svg'
import { ListingInfo } from 'job-listing/domains'

export const DraftStatusBanner = ({ listing }: { listing: ListingInfo }) => {
  const navigate = useNavigate()

  const step = listing.minimumSalary && listing.maximumSalary ? '3' : '2'

  const handleReview = () => navigate(`/listings/create/${listing.id}?step=${step}`)

  return (
    <div className="px-4 py-6 flex flex-col items-stretch gap-3 md:flex-row md:items-center justify-between bg-info-lighter rounded-lg">
      <div className="flex flex-row items-center gap-4">
        <AttentionIcon className="w-6 h-6 stroke-info-light fill-info-light" />
        <Text color="text-info-medium">
          Your listing is a draft. Publish it to get matched with candidates.
        </Text>
      </div>
      <PrimaryButton onClick={handleReview}>Review job listing</PrimaryButton>
    </div>
  )
}
