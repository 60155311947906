import { useMonitoring } from 'shared/hooks'
import { useNetwork } from 'shared/hooks'
import { useToast } from 'shared/hooks'
import { useMutation } from 'react-query'

export const usePublishScreening = (listingId: string, screeningId: string) => {
  const { post } = useNetwork()
  const { toastSuccess, toastError } = useToast()
  const { captureException } = useMonitoring()

  const { mutateAsync: publish, isLoading } = useMutation(
    async () => post(`listings/${listingId}/screenings/${screeningId}/publish`),
    {
      retry: 3,
      onSuccess: () => {
        toastSuccess('Screening published.')
      },
      onError: (error: RequestError) => {
        toastError(`Failed to publish the screening: ${error.response?.data.message}`)
        captureException(error)
      },
    },
  )

  return { publish, isLoading }
}
