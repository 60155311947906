import { useEffect, useState } from 'react'

const vettedByEmailKey = 'cmp'
const recruiterLinkKey = 'rec'
const referrerSlugKey = 'referral'
const inviteIdKey = 'inviteId'

// @todo: consider refactoring to be generic and handle a params list
export const useOnboardingParamsTracker = () => {
  const initialEmailValue = localStorage.getItem(vettedByEmailKey)
  const initiaRecruiterLinkValue = localStorage.getItem(recruiterLinkKey)
  const initialReferrerSlugValue = localStorage.getItem(referrerSlugKey)

  const [vettedByEmail, setVettedByEmail] = useState<string | null>(initialEmailValue)
  const [recruiterLink, setRecruiterLink] = useState<string | null>(initiaRecruiterLinkValue)
  const [referrerSlug, setReferrerSlug] = useState<string | null>(initialReferrerSlugValue)
  const [inviteId, setInviteId] = useState<string | null>(null)

  useEffect(() => {
    localStorage.setItem(vettedByEmailKey, vettedByEmail || '')
    localStorage.setItem(recruiterLinkKey, recruiterLink || '')
    localStorage.setItem(referrerSlugKey, referrerSlug || '')
  }, [vettedByEmail, recruiterLink, referrerSlug])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const email = urlParams.get(vettedByEmailKey)
    setVettedByEmail((previous) => email || previous)

    const link = urlParams.get(recruiterLinkKey)
    setRecruiterLink((previous) => link || previous)

    const referrerSlug = urlParams.get(referrerSlugKey)
    setReferrerSlug((previous) => referrerSlug || previous)

    const inviteId = urlParams.get(inviteIdKey)
    setInviteId(inviteId)
  }, [])

  const reset = () => {
    localStorage.removeItem(vettedByEmailKey)
    setVettedByEmail(null)
  }

  const handleReferrerSlugSetting = (slug: string | undefined) =>
    setReferrerSlug((previous) => slug || previous)

  return {
    vettedByEmail,
    recruiterLink,
    referrerSlug,
    inviteId,
    reset,
    setReferrerSlug: handleReferrerSlugSetting,
  }
}
