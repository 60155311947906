import { Modal } from 'shared/components/molecules'
import { Autocomplete, Input, Suggestion } from 'shared/components/atoms'
import { Label } from 'shared/components/atoms'
import { ContentWrapper } from 'components/ContentWrapper'

import { useForm } from 'shared/hooks'

import { ProfileEducation } from 'pages/JobSeekerProfile/types'
import { useEffect, useMemo } from 'react'
import { degreeTypeOptions } from 'pages/JobSeekerProfile'
import { Footer } from '../components/Footer'
import { useEducation } from 'pages/JobSeekerProfile/hooks/useEducation'
import { emptyState, schema } from '.'
import { setLocale } from 'yup'
import { Institution } from '../Certification/Institution'
import { EducationFormValues } from 'modules/profiles/domain'

interface EducationProps {
  profileId: string
  handleClose(): void
  existingEducation?: ProfileEducation
}

setLocale({
  mixed: {
    notType: 'Required field.',
    required: 'Required field.',
  },
})

export const Education = ({ handleClose, existingEducation, profileId }: EducationProps) => {
  const { upsertEducation, deleteEducation, parseEducationToFormSchema, isDeleting, isUpserting } =
    useEducation(profileId)

  const { register, handleSubmit, reset, setValue, getValues, watch, getError, trigger, isDirty } =
    useForm({
      schema,
      defaultValues: {},
    })

  const startDate = watch('startDate') as string
  const endDate = watch('endDate') as string

  const isEdit = useMemo(() => !!existingEducation, [existingEducation])

  useEffect(() => {
    if (endDate) trigger('endDate')
  }, [startDate])

  useEffect(() => {
    if (existingEducation) {
      return reset(parseEducationToFormSchema(existingEducation))
    }
    reset(emptyState)
  }, [existingEducation?.institution])

  const onSubmit = (shouldAddAnother: boolean = false) => {
    const submit = handleSubmit(async () => {
      const formValues = getValues() as EducationFormValues

      upsertEducation(formValues).then(() => {
        if (shouldAddAnother) return reset(emptyState)
        handleClose()
      })
    })

    submit()
  }

  const handleDelete = () => {
    deleteEducation(existingEducation!.id).then(() => {
      handleClose()
    })
  }

  const handleDegreeTypeChange = async (value: Suggestion) => {
    setValue('degreeTypeId', value.id, { shouldDirty: true })
    setValue('degreeType', value.label, { shouldDirty: true })
  }

  return (
    <Modal
      handleClose={handleClose}
      showDismissalConfirmation={isDirty}
      title={isEdit ? 'Edit education' : 'Add education'}
      content={
        <form
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit(false)
          }}
          id="educationForm"
        >
          <ContentWrapper isForm>
            <Institution
              register={register}
              setValue={setValue}
              watch={watch}
              getError={getError}
              title="Institution"
              profileId={profileId}
            />
          </ContentWrapper>
          <ContentWrapper isForm>
            <Label htmlFor="degreeType">Degree</Label>
            <Autocomplete
              name="degreeType"
              register={register}
              watch={watch}
              suggestions={degreeTypeOptions}
              handleAdd={handleDegreeTypeChange}
              placeholder="Degree type"
              clearInputValue={false}
              showHint={false}
              showDescription={false}
              allowCreation={false}
            />
          </ContentWrapper>
          <ContentWrapper isForm>
            <div className="grid grid-cols-2 md:inline-flex gap-5 sm:gap-6 w-full">
              <div className="w-full">
                <Label htmlFor="startDate">Start date</Label>
                <Input
                  id="startDate"
                  register={register}
                  name="startDate"
                  placeholder="Select a date"
                  type="date"
                />
              </div>
              <div className="w-full">
                <Label htmlFor="endDate">End date</Label>
                <Input
                  id="endDate"
                  register={register}
                  name="endDate"
                  placeholder="Select a date"
                  type="date"
                />
              </div>
            </div>
          </ContentWrapper>
          <ContentWrapper isForm>
            <Label htmlFor="fieldOfStudy">Major (field of study)</Label>
            <Input register={register} name="fieldOfStudy" placeholder="Major (field of study)" />
          </ContentWrapper>
        </form>
      }
      footer={
        <Footer
          isEdit={isEdit}
          onSubmit={onSubmit}
          handleClose={handleClose}
          handleDelete={handleDelete}
          form="educationForm"
          deleteButtonLabel="Delete education"
          isLoading={isUpserting}
          isDeleting={isDeleting}
        />
      }
    />
  )
}
