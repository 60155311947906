import { useEffect, useState } from 'react'

import { ApplicationModal, DeclinationModal, JobOpportunityModal } from './components'

import { useFetchJobOpportunity, useTrackJobOpportunity } from 'job-opportunity/hooks'
import { useBrowserURL } from 'shared/hooks'
import { useTracking } from 'tracking'
import {
  JobOpportunityInterestedClick,
  JobOpportunityNotInterestedClick,
} from 'tracking/events/job-opportunities'
import { VettingPackageResponse } from 'vetting/domain'

interface Props {
  jobOpportunityId?: string
  vetting?: VettingPackageResponse
  onClose: () => void
}

export const JobOpportunityModals = ({ jobOpportunityId = '', vetting, onClose }: Props) => {
  const { jobOpportunity, isLoading, refetch } = useFetchJobOpportunity(jobOpportunityId)
  const { view, startFilling } = useTrackJobOpportunity(jobOpportunity)
  const { replaceCurrentURL } = useBrowserURL()
  const { trackEvent } = useTracking()

  const [jobOpportunityModalOpen, setJobOpportunityModalOpen] = useState(false)
  const [rejectionModalOpen, setRejectionModalOpen] = useState(false)
  const [applicationModalOpen, setApplicationModalOpen] = useState(false)

  useEffect(() => {
    if (isLoading) return
    if (jobOpportunityId) replaceCurrentURL(`/job-opportunities/${jobOpportunityId}`)

    if (jobOpportunity?.isPending && jobOpportunity?.startedFillingAt) {
      return setApplicationModalOpen(true)
    }

    setJobOpportunityModalOpen(Boolean(jobOpportunityId))
  }, [jobOpportunityId, isLoading])

  useEffect(() => {
    view()
  }, [jobOpportunity?.id])

  const trackStartedFillingEvent = () => startFilling()

  const handleOpenJobOpportunityModal = () => {
    setJobOpportunityModalOpen(true)
    setApplicationModalOpen(false)
    setRejectionModalOpen(false)
  }

  const handleOpenRejectionModal = () => {
    setJobOpportunityModalOpen(false)
    setRejectionModalOpen(true)
    trackEvent(new JobOpportunityNotInterestedClick(jobOpportunityId))
  }

  const handleReject = () => {
    setRejectionModalOpen(false)
    onClose()
  }

  const handleOpenApplicationModal = () => {
    trackEvent(new JobOpportunityInterestedClick(jobOpportunityId))
    trackStartedFillingEvent()
    setJobOpportunityModalOpen(false)
    setApplicationModalOpen(true)
  }

  const handleCloseApplicationModal = () => {
    setApplicationModalOpen(false)
    onClose()
  }

  const handleSubmit = () => {
    setApplicationModalOpen(false)
    refetch()
    onClose()
  }

  if (!jobOpportunity) return null

  return (
    <>
      <JobOpportunityModal
        isOpen={jobOpportunityModalOpen}
        onClose={onClose}
        onReject={handleOpenRejectionModal}
        jobOpportunity={jobOpportunity}
        vetting={vetting}
        onApprove={handleOpenApplicationModal}
        refetchJobOpportunity={refetch}
      />

      <DeclinationModal
        open={rejectionModalOpen}
        onCancel={handleOpenJobOpportunityModal}
        onDecline={handleReject}
        jobOpportunity={jobOpportunity}
      />

      <ApplicationModal
        open={applicationModalOpen}
        onClose={handleCloseApplicationModal}
        onBack={handleOpenJobOpportunityModal}
        jobOpportunity={jobOpportunity}
        onSubmit={handleSubmit}
      />
    </>
  )
}
