import { useEffect, useState } from 'react'
import { object, string } from 'yup'

import { Text } from 'shared/components/atoms'
import { PrimaryButton, TertiaryButton } from 'shared/components/atoms'
import { Modal } from 'shared/components/molecules'
import { StyledSelect } from 'shared/components/atoms'
import { SelectOption } from 'shared/components/atoms'

import { ReactComponent as TrashIcon } from 'icons/trash.svg'

import { useForm } from 'shared/hooks'

import { Roles } from 'account/enums'
import { Teammate } from 'company/domain'
import { RemoveAccessDialog } from './RemoveAccessDialog'

interface EditPermissionModalProps {
  isOpen: boolean
  onClose: (newTeammates: Array<Teammate>) => void
  teammateIndex: number | undefined
  teammates: Array<Teammate>
}

const options = [
  { id: Roles.EmployerViewer, label: 'View only' },
  { id: Roles.EmployerOwner, label: 'Admin' },
] as Array<SelectOption>

export const EditPermissionModal = ({
  isOpen,
  onClose,
  teammateIndex,
  teammates,
}: EditPermissionModalProps) => {
  const teammate = teammateIndex ? teammates[teammateIndex] : undefined
  const { register, watch, setValue, getValues, reset, handleSubmit } = useForm({
    schema: object().shape({ role: string() }),
    defaultValues: { role: teammate?.role },
  })

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const handleChangeTeammatePermission = handleSubmit(() => {
    if (!teammate) return
    const { role } = getValues() as { role: Roles.EmployerOwner | Roles.EmployerViewer }

    teammate.role = role
    teammates.splice(teammateIndex!, 1, teammate)
    onClose(teammates)
  })

  const handleDelete = () => setShowDeleteDialog(true)
  const handleDeleteCancel = () => setShowDeleteDialog(false)
  const handleDeleteConfirm = () => {
    teammates.splice(teammateIndex!, 1)
    setShowDeleteDialog(false)
    onClose(teammates)
  }

  useEffect(() => {
    reset({ role: teammate?.role })
  }, [teammate?.role])

  if (!teammate) return null

  if (showDeleteDialog)
    return (
      <RemoveAccessDialog
        onDeleteCancel={handleDeleteCancel}
        onDeleteConfirm={handleDeleteConfirm}
        teammate={teammate}
      />
    )

  return (
    <Modal
      open={isOpen}
      mobilePositioning="bottom"
      title={
        <Text size="text-xl" weight="font-medium" className="text-neutral-darkest">
          Edit permission
        </Text>
      }
      content={
        <form className="flex flex-col sm:flex-row items-center gap-10">
          <Text size="text-base" weight="font-normal" className="text-neutral-darkest flex-grow">
            {teammate.email}
          </Text>
          <StyledSelect
            register={register}
            watch={watch}
            setValue={setValue}
            name="role"
            options={options}
            showFieldError={false}
            className="sm:!w-fit"
          />
        </form>
      }
      footer={
        <div className="w-full flex flex-row gap-4">
          <TertiaryButton
            icon={<TrashIcon className="stroke-danger-medium" />}
            onClick={handleDelete}
            className="mr-auto"
          />
          <TertiaryButton onClick={() => onClose(teammates)}>Cancel</TertiaryButton>
          <PrimaryButton onClick={handleChangeTeammatePermission}>Save changes</PrimaryButton>
        </div>
      }
      contentContainerClassName="!py-10"
      handleClose={() => onClose(teammates)}
    />
  )
}
