const JOB_OPPORTUNITY_ANSWERS_KEY = 'job-opportunity-answers'

export interface SavedAnswer {
  index: string
  value: string | number | null
  workExperiences: Array<{ id: string; label: string }>
  noExperience: Optional<boolean>
}

type JobOpportunityAnswers = {
  [jobOpportunityId: string]: Nullable<Array<SavedAnswer>>
}

const getJobOpportunityAnswersFromLocalStorage = () => {
  return JSON.parse(
    localStorage.getItem(JOB_OPPORTUNITY_ANSWERS_KEY) || '{}',
  ) as JobOpportunityAnswers
}

const setAnswerValue = (jobOpportunityId: string, value: SavedAnswer) => {
  const answers = getJobOpportunityAnswersFromLocalStorage()

  if (!answers[jobOpportunityId]) {
    answers[jobOpportunityId] = [value]
    return answers
  }

  const index = answers[jobOpportunityId]!.findIndex((answer) => answer.index === value.index)
  if (index === -1) {
    answers[jobOpportunityId] = [...answers[jobOpportunityId]!, value]
    return answers
  }

  answers[jobOpportunityId]![index] = value
  return answers
}

export const usePersisJobOpportunityAnswers = (jobOpportunityId: string) => {
  const persist = (value: SavedAnswer) => {
    const answers = setAnswerValue(jobOpportunityId, value)
    localStorage.setItem(JOB_OPPORTUNITY_ANSWERS_KEY, JSON.stringify(answers))
  }

  const getAnswers = () => {
    return getJobOpportunityAnswersFromLocalStorage()[jobOpportunityId]
  }

  const remove = () => {
    const answers = getJobOpportunityAnswersFromLocalStorage()

    const { [jobOpportunityId]: _, ...rest } = answers

    localStorage.setItem(JOB_OPPORTUNITY_ANSWERS_KEY, JSON.stringify(rest))
  }

  return { persist, getAnswers, remove }
}
