import { SignUpForm } from 'authentication/components'
import { cn } from 'modules/shared'

export const SignUpPanel = ({ redirectUrl }: { redirectUrl: string }) => {
  return (
    <main
      className={cn(
        'w-full lg:w-2/5',
        'flex flex-col items-center justify-center',
        'px-9 py-10 lg:px-16 lg:py-0',
      )}
    >
      <SignUpForm redirectUrl={redirectUrl} />
    </main>
  )
}
