import classNames from 'classnames'
import { cn } from 'modules/shared'
import { Title, Text } from 'shared/components/atoms'

interface Props {
  children: React.ReactNode
  id?: string
  title?: string | React.ReactNode
  disabled?: boolean
  print?: boolean
  showOnMobile?: boolean
  labelColor?: 'text-neutral-dark' | 'text-neutral-medium'
  titleSize?: 'heading' | 'micro-heading' | 'small-heading'
  mobileTitleSize?: 'heading' | 'small-heading' | 'micro-heading'
  className?: string
}

export const PreviewLabel = ({
  id,
  children,
  title,
  disabled,
  print = true,
  showOnMobile = true,
  titleSize = 'heading',
  mobileTitleSize = 'heading',
  labelColor = 'text-neutral-dark',
  className,
}: Props) => (
  <div
    aria-labelledby={id}
    className={classNames(
      'flex flex-col shrink-0 gap-2',
      {
        'print:hidden': !print,
        'hidden sm:flex': !showOnMobile,
      },
      className,
    )}
  >
    {title && (
      <Text id={id} className={cn(labelColor, 'text-xs sm:text-sm')}>
        {title}
      </Text>
    )}

    <Title
      size={mobileTitleSize}
      className={classNames('block sm:hidden', {
        'text-neutral-medium': disabled,
        'text-neutral-darkest': !disabled,
      })}
    >
      {children}
    </Title>
    <Title
      size={titleSize}
      className={classNames('hidden sm:block', {
        'text-neutral-medium': disabled,
        'text-neutral-darkest': !disabled,
      })}
    >
      {children}
    </Title>
  </div>
)
