import { lazy } from 'react'

import { ContentWrapper } from 'components/ContentWrapper'
import { SecondaryButton } from 'shared/components/atoms'

import { ProfileEmptyStateSection } from '../ProfileEmptyStateSection'
import { ProfileSectionWrapper } from '../ProfileSectionWrapper'
import { ProfileSectionTitle } from '../ProfileSectionTitle'

import { PayRate } from './components'
import { Preferences } from 'modules/profiles/profile-preferences'

const Illustration = lazy(() =>
  import('icons/ilustrations/streamlinehq-change-pay-rate.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)

interface PayRateSectionProps {
  preferences: Preferences
  readOnly: boolean
  onEdit(): void
  wrapperClassName?: string
}

export const PayRateSection = ({
  preferences: {
    payRate: { monthlyRate, hourlyRate, openToNegotiation },
  },
  readOnly,
  onEdit,
  wrapperClassName,
}: PayRateSectionProps) => {
  const hasValue = [
    monthlyRate.min,
    monthlyRate.max,
    hourlyRate.min,
    hourlyRate.max,
    openToNegotiation,
  ].some((value) => value)

  if (readOnly) return null

  return (
    <ProfileSectionWrapper className={wrapperClassName} aria-labelledby="pay-rate-title">
      {hasValue && (
        <ContentWrapper>
          <ProfileSectionTitle
            title="Pay rate"
            id="pay-rate-title"
            readOnly={readOnly}
            onButtonClick={onEdit}
            isButtonEdit
          />
        </ContentWrapper>
      )}
      <ContentWrapper>
        {hasValue ? (
          <PayRate
            monthlyRate={monthlyRate}
            hourlyRate={hourlyRate}
            openToNegotiation={openToNegotiation}
          />
        ) : (
          <ProfileEmptyStateSection
            title="Pay rate"
            content="Please set your desired pay rate so that we can send you job opportunities that match your expectations."
            actions={
              <SecondaryButton size="sm" onClick={onEdit} className="w-full sm:w-fit">
                Add pay rate
              </SecondaryButton>
            }
          >
            <Illustration className="h-[150px] min-w-[150px]" />
          </ProfileEmptyStateSection>
        )}
      </ContentWrapper>
    </ProfileSectionWrapper>
  )
}
