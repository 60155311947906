import { useEffect, useMemo } from 'react'

import { TertiaryButton, FieldHint } from 'shared/components/atoms'
import { Modal } from 'shared/components/molecules'
import { Label } from 'shared/components/atoms'
import { PrimaryButton } from 'shared/components/atoms'
import { ContentWrapper } from 'components/ContentWrapper'
import { useOverview } from 'hooks/profile/useOverview'
import { useForm } from 'shared/hooks'
import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { Input, LocationSearchInput, PhoneInput } from 'shared/components/atoms'
import { PositionsSlug } from 'modules/shared/enums'
import { Paragraph } from 'shared/components/atoms'

import { buildSchema } from '.'

interface AboutProps {
  profile: ProfileOverview
  handleClose(): void
}

export const About = ({ handleClose, profile }: AboutProps) => {
  const { updateOverview, parseOverviewToFormSchema, isUpdating } = useOverview(profile.id)

  const isProductDesigner = useMemo(
    () =>
      Boolean(
        profile.interestRoles.find(({ roleType }) =>
          [PositionsSlug.ProductDesigner, PositionsSlug.Design].includes(roleType),
        ),
      ),
    [profile.interestRoles],
  )

  const { register, handleSubmit, reset, getValues, setValue, getError, watch, isDirty } = useForm({
    schema: buildSchema(isProductDesigner),
    defaultValues: {},
  })
  const location = watch('location')

  const onSubmit = handleSubmit(async () => {
    const formValues = getValues()
    updateOverview({ overview: formValues, profile }).then(() => {
      handleClose()
    })
  })

  useEffect(() => {
    reset(parseOverviewToFormSchema(profile))
  }, [reset, profile])

  useEffect(() => {
    document.body.classList.add('!pointer-events-auto')

    return () => {
      document.body.classList.remove('!pointer-events-auto')
    }
  }, [])

  return (
    <Modal
      handleClose={handleClose}
      showDismissalConfirmation={isDirty}
      title="About"
      content={
        <form onSubmit={onSubmit} id="about-form">
          <ContentWrapper className="flex flex-col gap-5 sm:flex-row sm:gap-6 mt-4" isForm>
            <div className="flex-grow">
              <Label htmlFor="firstName">First name</Label>
              <Input register={register} name="firstName" />
            </div>
            <div className="flex-grow">
              <Label htmlFor="lastName">Last name</Label>
              <Input register={register} name="lastName" />
            </div>
          </ContentWrapper>
          <ContentWrapper isForm>
            <Label htmlFor="location">Where do you live?</Label>
            <LocationSearchInput
              name="location"
              setValue={setValue}
              error={getError('location')}
              location={location}
            />
          </ContentWrapper>
          <ContentWrapper isForm>
            <Label htmlFor="personalSite" isOptional={!isProductDesigner}>
              {isProductDesigner ? 'Portfolio link' : 'Personal website'}
            </Label>
            <Input register={register} name="personalSite">
              {isProductDesigner ? (
                <Paragraph weight="font-normal" size="body-sm" className="text-neutral-dark mt-2">
                  Don't need to be a proper website, you can send us any link that contains your
                  most relevant work so companies can see what you have been doing.
                </Paragraph>
              ) : (
                <></>
              )}
            </Input>
          </ContentWrapper>
          <ContentWrapper isForm>
            <Label htmlFor="gitRepositoriesUrl" isOptional>
              GitHub / GitLab
            </Label>
            <Input register={register} name="gitRepositoriesUrl" />
          </ContentWrapper>
          <ContentWrapper isForm>
            <Label htmlFor="whatsAppNumber" isOptional>
              WhatsApp number
            </Label>
            <PhoneInput
              register={register}
              watch={watch}
              setValue={setValue}
              name="whatsAppNumber"
              id="whatsAppNumber"
              placeholder="+55 12 34567 8910"
            />
            <FieldHint>Companies won't have access to your number.</FieldHint>
          </ContentWrapper>
        </form>
      }
      footer={
        <>
          <TertiaryButton size="md" onClick={handleClose} className="w-full md:w-fit">
            Cancel
          </TertiaryButton>
          <PrimaryButton
            size="md"
            type="submit"
            form="about-form"
            isLoading={isUpdating}
            className="w-full md:w-fit"
          >
            Save
          </PrimaryButton>
        </>
      }
    />
  )
}
