import { cn } from 'modules/shared'
import { Text } from 'shared/components/atoms'
import { Checkbox } from 'shared/components/atoms/Form/Checkbox'
import { CheckboxGroupOption } from '.'

interface Props {
  options: Array<CheckboxGroupOption>
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (key: string) => Array<any>
  disabled?: boolean
}

export const CheckboxGroup = ({ options, name, disabled, register }: Props) => {
  return (
    <div className="flex flex-col gap-1">
      {options.map((option) => (
        <div key={option.label} className="flex items-center py-2">
          <Checkbox
            disabled={disabled}
            id={option.name}
            name={`${name}.${option.name}`}
            register={register}
          />
          <label
            htmlFor={option.name}
            className={cn('flex !mb-0 select-none', {
              'cursor-pointer': !disabled,
              'cursor-not-allowed': disabled,
            })}
          >
            <Text size="text-sm" weight="font-normal" className="text-neutral-darkest mr-1">
              {option.label}
            </Text>
            <Text size="text-sm" weight="font-normal" className="text-neutral-medium">
              {option.hint}
            </Text>
          </label>
        </div>
      ))}
    </div>
  )
}
