import { useRef, useState } from 'react'

import { SecondaryButton, CurrencyInput, Label } from 'shared/components/atoms'
import { RateLowerThanRequestedBanner } from 'matching/components'
import { PaymentModel } from 'job-listing/enums'
import { FormControl } from 'shared/hooks'
import { ReactComponent as EditIcon } from 'icons/edit.svg'

interface SimulationFieldsProps {
  formControl: FormControl
  showRateWarning: boolean
  paymentModel: PaymentModel
}

export const SimulationFields = ({
  formControl,
  showRateWarning,
  paymentModel,
}: SimulationFieldsProps) => {
  const { register, watch, setValue } = formControl
  const [disableCompensation, setDisableCompensation] = useState(true)
  const [disableFee, setDisableFee] = useState(true)

  const compensationRef = useRef<HTMLInputElement | null>(null)
  const feeRef = useRef<HTMLInputElement | null>(null)

  const isHourly = paymentModel === PaymentModel.Hourly
  const feeLabel = isHourly ? 'Strider hourly fee' : 'Strider monthly fee'

  const handleEditFee = () => {
    setDisableFee(false)
    Promise.resolve().then(() => feeRef.current?.select())
  }

  const handleEditCompensation = () => {
    setDisableCompensation(false)
    Promise.resolve().then(() => compensationRef.current?.select())
  }

  return (
    <div>
      <div className="flex flex-col">
        <Label htmlFor="compensation">Candidate's expected pay rate for the job opening</Label>
        <div className="flex gap-4">
          <CurrencyInput
            name="compensation"
            register={register}
            watch={watch}
            setValue={setValue}
            alwaysValidate
            disabled={disableCompensation}
            className="flex-grow"
            ref={compensationRef}
          />
          {disableCompensation && (
            <SecondaryButton
              size="md"
              icon={<EditIcon className="h-[16px] w-[16px] stroke-neutral-darkest" />}
              onClick={handleEditCompensation}
            />
          )}
        </div>
        {showRateWarning && <RateLowerThanRequestedBanner className="mb-4 -mt-2" />}
      </div>
      <div className="flex flex-col">
        <Label htmlFor="fee">{feeLabel}</Label>
        <div className="flex gap-4">
          <CurrencyInput
            name="fee"
            register={register}
            watch={watch}
            setValue={setValue}
            alwaysValidate
            disabled={disableFee}
            className="flex-grow"
            ref={feeRef}
          />
          {disableFee && (
            <SecondaryButton
              size="md"
              icon={<EditIcon className="h-[16px] w-[16px] stroke-neutral-darkest" />}
              onClick={handleEditFee}
            />
          )}
        </div>
      </div>
    </div>
  )
}
