import { useState } from 'react'

import { ButtonType, DropdownWrapper, Paragraph, ProgressBar, Text } from 'shared/components/atoms'

import { Countdown } from 'components/molecules/Countdown/Countdown'

import { JobOpportunity } from './components/JobOpportunity'

import { ReactComponent as BellIcon } from 'icons/bell-ringing.svg'
import { ReactComponent as Arrow } from 'icons/dropdown_arrow.svg'

import { useJobOpportunities } from 'job-opportunity/exports'
import { useDate, useInterval } from 'shared/hooks'
import { useTracking } from 'tracking'
import {
  JobOpportunitiesDropdownOpened,
  JobOpportunityStatusExplanationClick,
} from 'tracking/events/job-opportunities'
import { JobOpportunitiesImpressionTracker } from '../JobOpportunitiesImpressionTracker'
import { usePendingTasks } from '../../hooks'
import { VettingPackageResponse } from 'vetting/domain'

import { JobOpportunity as JobOpportunityDomain } from 'job-opportunity/domain'

import { useHelp } from 'modules/help'

interface Props {
  jobOpportunities: Array<JobOpportunityDomain>
  vetting?: VettingPackageResponse
  onItemClick: (id: string) => void
}

const FT_JOB_OPPORTUNITIES = process.env.REACT_APP_FT_JOB_OPPORTUNITIES === 'true'
const INTERCOM_ARTICLE_ID = 8822147

export const JobOpportunities = ({ jobOpportunities, vetting, onItemClick }: Props) => {
  const [now, setNow] = useState(new Date())

  const { clear } = useInterval(() => setNow(new Date()), jobOpportunities.length ? 1000 : null)

  const { getDifferenceInSeconds } = useDate()
  const { getTimeRemainingColors, getProgressBarPercentage, getJobOpportunityClosestToDeadline } =
    useJobOpportunities()
  const { showArticle } = useHelp()
  const { trackEvent } = useTracking()
  const { holdJobOpportunityTasks } = usePendingTasks(vetting)

  const jobOpportunity = getJobOpportunityClosestToDeadline(jobOpportunities)

  const showCountdown = Boolean(jobOpportunity)
  const showPendingTasks =
    Boolean(holdJobOpportunityTasks.length) &&
    Boolean(jobOpportunities.find(({ isApplied }) => isApplied))

  const { progressBarColor, textColor } = getTimeRemainingColors(
    jobOpportunity ? getDifferenceInSeconds(now, jobOpportunity.deadlineAt) : undefined,
  )

  const missingTime = jobOpportunity
    ? Math.trunc(getDifferenceInSeconds(now, jobOpportunity.deadlineAt))
    : undefined

  const percentage =
    (missingTime ?? 0) <= 0 || !jobOpportunity
      ? 0
      : getProgressBarPercentage(jobOpportunity.createdAt, jobOpportunity.deadlineAt, now)

  const openArticle = () => {
    trackEvent(new JobOpportunityStatusExplanationClick(INTERCOM_ARTICLE_ID))
    showArticle(INTERCOM_ARTICLE_ID)
  }
  const trackDropdownOpened = () => trackEvent(new JobOpportunitiesDropdownOpened())

  if (jobOpportunities.length && !jobOpportunity) clear()

  if (!FT_JOB_OPPORTUNITIES || jobOpportunities.length === 0) return null

  return (
    <>
      <DropdownWrapper
        maxWidth="w-[320px] xs:w-[460px] md:w-[500px]"
        icon={<BellIcon className="-rotate-[30deg]" />}
        iconContainerClassName="bg-neutral-darkest p-2 rounded-xl"
        buttonType={ButtonType.Tertiary}
        triggerClassName="h-fit w-full max-w-[320px] xs:max-w-[437px] gap-5 bg-neutral-darker hover:bg-neutral-darkest-0.48"
        contentClassName="p-0 rounded-xl shadow-[0px_6px_32px_4px_#00000014]"
        align="center"
        triggerContent={
          <>
            <div className="flex flex-col gap-2 w-full" onClick={trackDropdownOpened}>
              <div className="flex flex-col gap-2 xs:flex-row justify-between">
                <Text size="text-sm" weight="font-medium" className="text-neutral-day">
                  Job opportunities{' '}
                  {showPendingTasks && showCountdown ? (
                    <span className="text-warning-light">(On hold)</span>
                  ) : null}
                </Text>
                {showCountdown && <Countdown seconds={missingTime ?? 0} className={textColor} />}
                {!showCountdown && showPendingTasks && (
                  <Text size="text-sm" weight="font-normal" className="text-warning-light">
                    On hold
                  </Text>
                )}
              </div>
              {showCountdown && (
                <ProgressBar
                  progress={percentage}
                  color={progressBarColor}
                  background="bg-neutral-darkest"
                />
              )}
            </div>
            <Arrow className="stroke-neutral-day w-4 h-4" />
          </>
        }
        footer={
          <div className="flex items-center justify-center px-6 pt-4 pb-6">
            <Paragraph className="text-neutral-dark">
              Questions on your job applications status?{' '}
              <span onClick={openArticle} className="font-inherit text-info-medium cursor-pointer">
                Learn more
              </span>
            </Paragraph>
          </div>
        }
      >
        <div className="max-h-[50vh] md:max-h-[60vh] overflow-y-auto">
          {jobOpportunities.map((jobOpportunity) => (
            <JobOpportunity
              key={jobOpportunity.id}
              jobOpportunity={jobOpportunity}
              pendingTask={showPendingTasks}
              onClick={onItemClick}
            />
          ))}
        </div>
      </DropdownWrapper>
      <JobOpportunitiesImpressionTracker />
    </>
  )
}
