import { AxiosResponse } from 'axios'
import { UseFormSetValue } from 'react-hook-form'
import { SkillItem } from './SkillItem'
import { FieldHint, Label, Suggestion } from 'shared/components/atoms'
import { Skill } from 'profile/types'
import { emptySkill } from '.'
import classNames from 'classnames'
import { YearOfExperienceOptions, yearsOfExperienceOptions } from 'pages/JobSeekerProfile'

const MAX_NUMBER_OF_SKILLS = 10

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (key: string) => Array<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<Record<string, any>>
  stacks: Array<Skill>
  stackOptions: Array<Suggestion>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createOption: (label: string) => Promise<AxiosResponse<Suggestion, any>>
  className?: string
  name?: string
  showHint?: boolean
  showLabel?: boolean
  minRequiredSkills?: number
  allowCreation?: boolean
  yearsOptions?: YearOfExperienceOptions[]
}

export const MainSkillsSelect = ({
  stacks,
  stackOptions,
  setValue,
  createOption,
  className,
  name = 'stacks',
  showHint = true,
  showLabel = true,
  minRequiredSkills = 3,
  allowCreation = true,
  yearsOptions = yearsOfExperienceOptions,
}: Props) => {
  const anyEmptyRow = () => stacks.find(({ stackId }) => !stackId)

  if (!stacks.length) {
    stacks = [...Array(minRequiredSkills)].map((_) => {
      return { ...emptySkill }
    })
  }
  if (!anyEmptyRow() && stacks.length < MAX_NUMBER_OF_SKILLS) stacks.push({ ...emptySkill })

  const handleAddStack = () => {
    if (stacks.length === MAX_NUMBER_OF_SKILLS) return
    if (!stacks.find(({ stackId }) => stackId === 0)) {
      const newStacks = stacks.concat([{ stackId: 0, stackLabel: '', yearsOfExperience: 0 }])
      setValue(name, newStacks, { shouldValidate: true })
    }
  }

  const handleRemoveStack = (index: number) => {
    setValue(
      name,
      stacks.filter((_item, currentIndex) => index !== currentIndex),
      { shouldValidate: true },
    )
  }

  return (
    <div className={classNames(className)}>
      <div className="w-full">
        {showLabel && (
          <div className="flex gap-4 mb-2">
            <Label htmlFor="mainSkills" size="text-sm">
              What are your main skills?
            </Label>
            <FieldHint className="!mt-0">select at least 3 skills</FieldHint>
          </div>
        )}
        {stacks.map((stack, index) => {
          return (
            <SkillItem
              key={index}
              index={index}
              stack={stack}
              stacks={stacks}
              isRemoveVisible={stacks.length > minRequiredSkills}
              onRemoveStack={() => handleRemoveStack(index)}
              stackOptions={stackOptions}
              setValue={setValue}
              createOption={createOption}
              onAddSkill={handleAddStack}
              name={name}
              allowCreation={allowCreation}
              yearsOptions={yearsOptions}
            />
          )
        })}
      </div>
      {showHint && (
        <span className="font-normal text-sm text-neutral-dark">{`${
          stacks.filter((stack) => Boolean(stack.stackId)).length
        } of ${MAX_NUMBER_OF_SKILLS} main skills`}</span>
      )}
    </div>
  )
}
