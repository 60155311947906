import { DatePicker } from 'shared/components/atoms'
import { SecondaryButton } from 'shared/components/atoms'
import { buildNoticePeriodLabel, isMissingNoticePeriod } from 'components/molecules/NoticePeriod'
import { Text } from 'components/Text'
import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { useMemo } from 'react'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { DateTag } from '../../DateTag'

interface StartDateFieldProps {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<Record<string, any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<Record<string, any>>
  getError: (key: string) => string | undefined
  profile: ProfileOverview
}

const getMinDate = (): Date => {
  const today = new Date()
  today.setUTCDate(today.getUTCDate() + 7)
  today.setUTCHours(0, 0, 0, 0)

  return today
}

export const StartDateField = ({
  setValue,
  watch,
  getError,
  name,
  profile,
}: StartDateFieldProps) => {
  const notificePeriodAvailable = !isMissingNoticePeriod(profile.noticePeriodDays)
  const noticePeriodLabel = buildNoticePeriodLabel(profile.noticePeriodDays)
  const dates: Array<Date> = watch('preferredStartDates') || []
  const error = getError(name)
  const minDate = getMinDate()

  const sortedStartDates = useMemo(
    () =>
      dates.sort((a: Date, b: Date) => {
        if (a < b) return -1
        return 1
      }),
    [dates],
  )

  return (
    <div className="flex flex-col items-start gap-4 mt-4">
      {notificePeriodAvailable && (
        <Text size="caption" color="text-neutral-dark">{`${
          profile.firstName
        } will be ready to start${
          profile.noticePeriodDays !== 0 && ' in'
        } ${noticePeriodLabel}.`}</Text>
      )}
      {Boolean(sortedStartDates.length) && (
        <div className="flex flex-wrap flex-row gap-2">
          {sortedStartDates.map((date: Date) => (
            <DateTag date={date} noticeDays={profile.noticePeriodDays || 0} key={date.toString()} />
          ))}
        </div>
      )}
      <div className="flex flex-row items-center gap-4 justify-start">
        <DatePicker
          name={name}
          setValue={setValue}
          watch={watch}
          trigger={<SecondaryButton size="sm">Select dates</SecondaryButton>}
          minDate={minDate}
          showPanel
          disableWeekends
        />
        <Text size="small-body" color={error ? 'text-danger-dark' : 'text-neutral-dark'}>
          Enter a minimum of 2 and a maximum of 10 dates
        </Text>
      </div>
    </div>
  )
}
