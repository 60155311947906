import { ReactComponent as SettingsIcon } from 'icons/listings/settings.svg'
import { ReactComponent as SettingsFilledIcon } from 'icons/navbar/settings-filled.svg'

import { ReactComponent as SearchIcon } from 'icons/navbar/candidates-search.svg'
import { ReactComponent as SearchFilledIcon } from 'icons/navbar/candidates-search-filled.svg'

import { ReactComponent as BriefcaseOutlinedIcon } from 'icons/briefcase-02-outlined.svg'
import { ReactComponent as BriefcaseIcon } from 'icons/briefcase-02.svg'
import { ReactComponent as HomeOutlinedIcon } from 'icons/home-line-outlined.svg'
import { ReactComponent as HomeIcon } from 'icons/home-line.svg'
import { ReactComponent as StarsOutlinedIcon } from 'icons/benefits/star-06-outlined.svg'
import { ReactComponent as StarsIcon } from 'icons/star-06.svg'
import { ReactComponent as PassportIcon } from 'icons/profile/passport.svg'
import { ReactComponent as PassportOutlinedIcon } from 'icons/profile/passport-outlined.svg'
import { ReactComponent as ShareOutlinedIcon } from 'icons/share-07.svg'
import { ReactComponent as ShareIcon } from 'icons/share-07-filled.svg'
import { ReactComponent as CoinsIcon } from 'icons/coins-stacked-03.svg'

import { useMemo } from 'react'
import { useAuth } from 'shared/hooks'
import { useLocation } from 'react-router-dom'
import { useReferralPageSeen } from './useReferralPageSeen'

export const useNavBar = ({ iconClassName }: { iconClassName: string }) => {
  const { user } = useAuth()
  const location = useLocation()
  const { isSeen, setSeen } = useReferralPageSeen()

  const navigationOptions = useMemo(
    () =>
      [
        {
          icon: <BriefcaseOutlinedIcon className={iconClassName} />,
          activeIcon: <BriefcaseIcon className={iconClassName} />,
          label: 'Job listings',
          to: '/',
          isActive: location.pathname === '/' || Boolean(location.pathname.match(/listings/)),
          constraints: [user.isOnboarded, user.isEmployer],
        },
        {
          icon: <HomeOutlinedIcon className={iconClassName} />,
          activeIcon: <HomeIcon className={iconClassName} />,
          label: 'Home',
          to: '/',
          isActive: location.pathname === '/',
          constraints: [user.isOnboarded, user.isSeeker],
        },
        {
          icon: <SettingsIcon className="stroke-white" />,
          activeIcon: <SettingsFilledIcon className={iconClassName} />,
          label: 'Settings',
          to: '/settings',
          isActive: Boolean(location.pathname.match(/settings/)),
          constraints: [user.isOnboarded, user.isEmployer],
        },
        {
          icon: <SearchIcon />,
          activeIcon: <SearchFilledIcon className={iconClassName} />,
          label: 'Search',
          to: '/candidates/search',
          isActive: Boolean(location.pathname.match(/candidates\/search/)),
          constraints: [user.isOnboarded, user.isStriderStaff],
        },
        {
          icon: <PassportOutlinedIcon className={iconClassName} />,
          activeIcon: <PassportIcon className={iconClassName} />,
          label: 'My profile',
          to: '/profile',
          isActive: Boolean(location.pathname.match(/profile/)),
          constraints: [user.isOnboarded, user.isSeeker],
        },
        {
          icon: <ShareOutlinedIcon className={iconClassName} />,
          activeIcon: <ShareIcon className={iconClassName} />,
          label: 'Refer',
          to: '/refer',
          isActive:
            Boolean(location.pathname.match(/refer/)) ||
            (location.pathname === '/' && !user.isSeeker),
          constraints: [user.isRecruiter || user.isSeeker],
        },
        {
          icon: <StarsOutlinedIcon className={iconClassName} />,
          activeIcon: <StarsIcon className={iconClassName} />,
          label: 'Benefits',
          to: '/benefits',
          isActive: Boolean(location.pathname.match(/benefits/)),
          constraints: [user.isOnboarded, user.isSeeker || user.isRecruiter],
        },
        {
          label: 'English Check',
          to: '/english-check/evaluations',
          isActive: Boolean(location.pathname.match(/english-check\/evaluation/)),
          constraints: [user.isEnglishEvaluator],
          hiddenOnMobile: true,
        },
        {
          icon: <BriefcaseOutlinedIcon className={iconClassName} />,
          activeIcon: <BriefcaseIcon className={iconClassName} />,
          label: 'Job board',
          to: '/job-board',
          isActive: Boolean(location.pathname.match(/job-board/)),
          constraints: [user.isRecruiter],
        },
        {
          icon: <CoinsIcon className={iconClassName} />,
          activeIcon: <CoinsIcon className={iconClassName} />,
          label: 'Referrals',
          to: '/referrals',
          isActive: Boolean(location.pathname.match(/referrals/)),
          constraints: [user.isEmployer],
          isSeen,
          onClick: setSeen,
        },
      ].filter(({ constraints }) => constraints.every((valid) => valid)),
    [user, location],
  )

  return { navigationOptions }
}
