import { ProfileStatus } from 'modules/candidates/enums'
import { CompanySize } from 'company/enums'
import { Preferences } from '../profile-preferences'

export interface FetchPreferencesResponse {
  jobSearchAvailability: {
    status: ProfileStatus
    days: string | null
  }
  payRate: {
    monthlyRate: {
      min: number | null
      max: number | null
    }
    hourlyRate: {
      min: number | null
      max: number | null
    }
    openToNegotiation: boolean
  }
  noticePeriodDays: number
  workingTime: {
    longTerm: boolean
    shortTerm: boolean
    hoursPerWeek?: {
      min: number
      max: number
    }
  }
  desirableCompanySize: Array<{ companySize: CompanySize; size: 'ideal' | 'yes' | 'no' }>
  notWillingToWorkIn: Array<number>
  allowsAdvertiseProfile: boolean
}

export class FetchPreferencesDTO {
  constructor(private readonly preferences: FetchPreferencesResponse) {}

  get companySizes() {
    const companySizes = {} as Record<CompanySize, 'ideal' | 'yes' | 'no'>

    this.preferences.desirableCompanySize.forEach((size) => {
      companySizes[size.companySize as CompanySize] = size.size
    })

    return companySizes
  }

  get hourlyRate() {
    const { min, max } = this.preferences.payRate.hourlyRate
    return {
      min: min || undefined,
      max: max || undefined,
    }
  }

  get monthlyRate() {
    const { min, max } = this.preferences.payRate.monthlyRate
    return {
      min: min || undefined,
      max: max || undefined,
    }
  }

  toDomain(): Preferences {
    return {
      availability: this.preferences.jobSearchAvailability.status,
      pausedUntil: this.preferences.jobSearchAvailability.days || undefined,
      payRate: {
        hourlyRate: this.hourlyRate,
        monthlyRate: this.monthlyRate,
        openToNegotiation: this.preferences.payRate.openToNegotiation,
      },
      noticePeriod:
        this.preferences.noticePeriodDays === null ? null : this.preferences.noticePeriodDays / 7,
      jobTypes: {
        longTerm: this.preferences.workingTime.longTerm,
        shortTerm: this.preferences.workingTime.shortTerm,
      },
      freelancingJobHours: {
        min: this.preferences.workingTime.hoursPerWeek?.min || 5,
        max: this.preferences.workingTime.hoursPerWeek?.max || 40,
      },
      companySizes: this.companySizes,
      notWillingToWorkIn: this.preferences.notWillingToWorkIn.map((id) => ({
        id,
        label: '',
      })),
      allowsAdvertiseProfile: this.preferences.allowsAdvertiseProfile || false,
    }
  }
}
