import { useState } from 'react'

const REFERRAL_BANNER_DISMISSED_KEY = 'referral_banner_dismissed'

export const useReferralBannerDismissed = () => {
  const initialState = JSON.parse(localStorage.getItem(REFERRAL_BANNER_DISMISSED_KEY) || 'false')
  const [isDismissed, setIsDismissed] = useState(initialState)

  const setDismissed = () => {
    localStorage.setItem(REFERRAL_BANNER_DISMISSED_KEY, 'true')
    setIsDismissed(true)
  }

  return { isDismissed, setDismissed }
}
