import { Suspense, lazy } from 'react'

import { Spinner, Text } from 'shared/components/atoms'
import { SignUpPanel } from './components/SignUpPanel'
import { SidePanel } from '../../components/SidePanel'

import { ReactComponent as BlurLight } from 'icons/landing/blur-refer.svg'
import { ReactComponent as CheckIcon } from 'icons/landing/check-icon.svg'
import { cn } from 'modules/shared'

const ReferBackground = lazy(() =>
  import('icons/landing/refer-bg.svg').then((module) => ({ default: module.ReactComponent })),
)

const BENEFITS = [
  'Set your own schedule and refer at your own pace',
  'Unlock a new income stream in USD',
  'Get paid when candidates you refer complete their profile and again when they’re hired',
  'Enable your talent network to work for US companies and earn more',
]

const TITLE = 'Refer talent and earn up to $1000 per hire'

export const RecruiterLandingPage = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const redirectUrl = urlParams.get('redirectUrl') || '/recruiter-onboarding'

  return (
    <div className="flex flex-col-reverse lg:flex-row lg:h-screen lg:overflow-hidden">
      <SignUpPanel redirectUrl={redirectUrl} />
      <SidePanel className="relative px-8 pt-8 pb-16 lg:px-24 lg:pt-24 lg:pb-0 flex flex-col items-start overflow-hidden">
        <h1
          className={cn(
            'text-neutral-day font-inter font-medium whitespace-pre-wrap',
            '3xl:text-[2.25rem] 3xl:leading-[3.25rem] 3xl:tracking-[-0.02em]',
            'lg:text-[2rem] lg:leading-10 lg:tracking-[-0.02em] lg:mb-10',
            'text-xl leading-[1.875rem] mb-6',
          )}
        >
          {TITLE}
        </h1>
        <ul className="lg:mb-20 flex flex-col gap-4 z-10">
          {BENEFITS.map((benefit, index) => (
            <li key={index} className="flex flex-row justify-start items-start gap-4">
              <CheckIcon className="min-w-[20px]" />
              <Text size="text-sm" weight="font-normal" className="text-neutral-day 3xl:text-base">
                {benefit}
              </Text>
            </li>
          ))}
        </ul>

        <Suspense fallback={<Spinner />}>
          <ReferBackground
            className="hidden lg:block absolute z-10
          left-24 right-0 bottom-[-340px]
          w-[1100px] h-[1178px]
          2xl:w-[1180px] 2xl:h-[1200px]
          3xl:w-[1720px] 3xl:h-[1100px]"
          />
        </Suspense>

        <BlurLight className="absolute w-full h-full left-0 bottom-[-80px] lg:bottom-0 lg:right-0" />
      </SidePanel>
    </div>
  )
}
