import classNames from 'classnames'
import styles from './Checkbox.module.scss'

interface CheckboxProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (key: string) => Array<any>
  name: string
  id?: string
  disabled?: boolean
  value?: string | number | boolean
  checked?: boolean
  onClick?: () => void
}

const Checkbox = ({ register, name, disabled, id, value, checked, onClick }: CheckboxProps) => {
  const [, fieldRegister] = register(name)

  return (
    <div className={classNames('inline-block mr-4', [styles.custom__checkbox])}>
      <input
        type="checkbox"
        name={name}
        id={id}
        disabled={disabled}
        value={value}
        checked={checked}
        onClick={onClick}
        {...fieldRegister()}
      />
    </div>
  )
}

export { Checkbox }
