import classNames from 'classnames'
import { Text } from 'shared/components/atoms'
import { OrganizationIcon } from 'shared/components/molecules'
import { Tooltip } from 'shared/components/atoms'

import { ReactComponent as BuildingIcon } from 'icons/listings/building-06-outlined.svg'
import { Company } from 'job-listing/dtos'

interface OwnerLabelProps {
  company?: Company
  className?: string
}

export const OwnerLabel = ({ company, className }: OwnerLabelProps) => {
  if (!company) return null

  return (
    <Tooltip content="Client">
      <div className={classNames('flex items-center gap-2 shrink-0', className)}>
        <OrganizationIcon
          domain={company.website}
          icon={<BuildingIcon className="w-4 h-4 stroke-neutral-medium" />}
          size="sm"
        />

        <Text size="text-sm" weight="font-normal">
          {company.name}
        </Text>
      </div>
    </Tooltip>
  )
}
