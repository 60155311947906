import { lazy } from 'react'
import classNames from 'classnames'

import { SecondaryButton } from 'shared/components/atoms'
import { ContentWrapper } from 'components/ContentWrapper'
import { CertificationRecord } from './CertificationRecord'
import { ProfileSectionTitle } from '../ProfileSectionTitle'
import { ProfileSectionWrapper } from '../ProfileSectionWrapper'
import { ProfileEmptyStateSection } from '../ProfileEmptyStateSection'

import { ProfileCertification } from 'pages/JobSeekerProfile/types'
import { TruncateButton } from 'shared/components/molecules'
import { useTruncateItems } from 'shared/components/molecules/TruncateButton/hooks'

const CertificateIllustration = lazy(() =>
  import('icons/ilustrations/streamlinehq-certificate.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)

interface ProfileCertificateSectionProps {
  readOnly: boolean
  certifications: ProfileCertification[]
  onAddCertificationClick(): void
  onEditCertificationClick(certification: ProfileCertification): void
  wrapperClassName?: string
  titleSize?: 'heading' | 'large-heading'
  placeholder?: JSX.Element
  shouldTruncate?: boolean
}

export const ProfileCertificateSection = ({
  readOnly,
  certifications,
  onAddCertificationClick,
  onEditCertificationClick,
  wrapperClassName,
  titleSize,
  placeholder,
  shouldTruncate,
}: ProfileCertificateSectionProps) => {
  const { visibleItems, truncatedItemsCount, setTruncate } = useTruncateItems<ProfileCertification>(
    certifications,
    shouldTruncate,
  )

  const handleTruncateClick = () => setTruncate(false)

  if (!certifications.length && readOnly) return placeholder ?? null

  return (
    <ProfileSectionWrapper
      aria-labelledby="certifications-title"
      className={classNames('print:break-inside-avoid-page', wrapperClassName)}
    >
      {Boolean(certifications.length) && (
        <ContentWrapper>
          <ProfileSectionTitle
            readOnly={readOnly}
            titleSize={titleSize}
            title="Certifications"
            id="certifications-title"
            onButtonClick={onAddCertificationClick}
          />
        </ContentWrapper>
      )}
      <ContentWrapper>
        {visibleItems.length ? (
          visibleItems.map((certification, index) => (
            <CertificationRecord
              className={classNames({
                '!mb-0': index === visibleItems.length - 1,
              })}
              readOnly={readOnly}
              onButtonClick={onEditCertificationClick}
              key={certification.id}
              certification={certification}
            />
          ))
        ) : (
          <ProfileEmptyStateSection
            id="certifications-title"
            title="Certifications"
            content="Share relevant certifications or licenses you have attained."
            actions={
              <SecondaryButton
                size="sm"
                onClick={onAddCertificationClick}
                className="w-full sm:w-fit"
              >
                Add certification
              </SecondaryButton>
            }
          >
            <CertificateIllustration className="h-[150px] min-w-[150px]" />
          </ProfileEmptyStateSection>
        )}
      </ContentWrapper>
      <TruncateButton truncatedItemsCount={truncatedItemsCount} onClick={handleTruncateClick}>
        Show other {truncatedItemsCount} certifications
      </TruncateButton>
    </ProfileSectionWrapper>
  )
}
