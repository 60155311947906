import { cn } from 'modules/shared'

interface RowProps {
  width?:
    | 'w-1/12'
    | 'w-2/12'
    | 'w-3/12'
    | 'w-4/12'
    | 'w-5/12'
    | 'w-6/12'
    | 'w-7/12'
    | 'w-8/12'
    | 'w-9/12'
    | 'w-10/12'
    | 'w-11/12'
    | 'w-12/12'
  height?: 'h-40' | 'h-10' | 'h-6' | 'h-3'
}

export const Row = ({ width, height }: RowProps) => {
  const className = cn('flex h-6 w-8/12 bg-gray-200 rounded mb-2', width, height)
  return <div className={className} />
}
