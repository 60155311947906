import { ReactComponent as InfoIcon } from 'icons/info-circle.svg'

interface BannerProps {
  children: React.ReactNode
  icon?: JSX.Element
}

export const Banner = ({
  children,
  icon = <InfoIcon className="w-[20px] h-[20px] stroke-info-medium" />,
}: BannerProps) => {
  return (
    <div className="flex gap-2 p-4 rounded text-sm bg-info-lighter">
      <span>{icon}</span>
      {children}
    </div>
  )
}
