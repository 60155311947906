import { Text } from 'components/Text'

import { ReactComponent as NotAvailableBadge } from 'icons/not-available-badge.svg'
import { ReactComponent as PausedBadge } from 'icons/paused.svg'
import classNames from 'classnames'
import { ProfileStatus } from 'modules/candidates/enums'

interface Props {
  status: ProfileStatus | null | undefined
  days: number | null | undefined
  companyName?: string
  withBackground?: boolean
  showIcon?: boolean
  className?: string
}

export const ProfileStatusBadge = ({
  status,
  days,
  companyName,
  withBackground = false,
  showIcon = true,
  className,
}: Props) => {
  const badges = {
    active: {
      icon: <div className="w-3 h-3 rounded-full bg-success-dark" />,
      label: (
        <Text size="caption" className="text-success-dark">
          Actively looking
        </Text>
      ),
      background: 'bg-success-lighter',
    },
    passive: {
      icon: <div className="w-3 h-3 border-2 border-info-medium rounded-full bg-transparent" />,
      label: (
        <Text size="caption" color="text-info-dark">
          Passively looking
        </Text>
      ),
      background: 'bg-info-lighter',
    },
    paused: {
      icon: <PausedBadge />,
      label: (
        <Text size="caption" color="text-neutral-dark">
          Paused ({days?.toFixed(0)} days left)
        </Text>
      ),
      background: 'bg-neutral-lightest',
    },
    unavailable: {
      icon: <NotAvailableBadge />,
      label: (
        <Text size="caption" color="text-warning-dark">
          Not available
        </Text>
      ),
      background: 'bg-warning-lighter',
    },
    // hired: {
    //   icon: alertIcon,
    //   label: (
    //     <Text size="caption" color="text-warning-dark">
    //       Hired by {companyName}
    //     </Text>
    //   ),
    // },
  }

  if (!status || !badges[status]) return null
  return (
    <div
      className={classNames(
        'flex gap-2 items-center shrink-0 print:hidden',
        {
          [badges[status].background]: withBackground,
          'px-3 py-2 rounded': withBackground,
        },
        className,
      )}
    >
      {showIcon && badges[status].icon}
      {badges[status].label}
    </div>
  )
}
