import { lazy, useMemo } from 'react'

import { ContentWrapper } from 'components/ContentWrapper'
import { SecondaryButton } from 'shared/components/atoms'

import { ProfileEmptyStateSection } from '../ProfileEmptyStateSection'
import { ProfileSectionWrapper } from '../ProfileSectionWrapper'
import { ProfileSectionTitle } from '../ProfileSectionTitle'

import { Availability, CompanySizes, JobTypes, NotInterested, ReadyToStart } from './components'

import { Preferences } from 'modules/profiles/profile-preferences'
import { ProfileFulfillment } from 'pages/JobSeekerProfile/types'
import { ProfileSections } from 'modules/profiles/enums'

const SettingsIllustration = lazy(() =>
  import('icons/ilustrations/streamlinehq-change-settings.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)

interface PreferencesSectionProps {
  preferences: Preferences
  readOnly: boolean
  onEdit(): void
  wrapperClassName?: string
  fulfillment: ProfileFulfillment
}

export const PreferencesSection = ({
  preferences,
  fulfillment: { rules },
  readOnly,
  onEdit,
  wrapperClassName,
}: PreferencesSectionProps) => {
  const hasValue = useMemo(
    () => Boolean(rules.find(({ name }) => name === ProfileSections.Preferences)?.complete),
    [rules],
  )

  if (readOnly) return null

  return (
    <ProfileSectionWrapper className={wrapperClassName} aria-labelledby="job-preferences-title">
      {Boolean(hasValue) && (
        <ContentWrapper>
          <ProfileSectionTitle
            id="job-preferences-title"
            readOnly={readOnly}
            title="Job preferences"
            onButtonClick={onEdit}
            isButtonEdit
          />
        </ContentWrapper>
      )}
      <ContentWrapper>
        {hasValue ? (
          <div className="flex flex-col items-start gap-10">
            <Availability preferences={preferences} />
            <ReadyToStart preferences={preferences} />
            <JobTypes preferences={preferences} />
            <NotInterested preferences={preferences} />
            <CompanySizes preferences={preferences} />
          </div>
        ) : (
          <ProfileEmptyStateSection
            id="job-preferences-title"
            title="Preferences"
            content="Configure your job-related preferences so that we can match you with the right opportunities."
            actions={
              <SecondaryButton size="sm" onClick={onEdit} className="w-full sm:w-fit">
                Add preferences
              </SecondaryButton>
            }
          >
            <SettingsIllustration className="h-[150px] min-w-[150px]" />
          </ProfileEmptyStateSection>
        )}
      </ContentWrapper>
    </ProfileSectionWrapper>
  )
}
