import { useMutation, useQuery, useQueryClient } from 'react-query'

import { useMonitoring, useNetwork } from 'shared/hooks'

export const useStacks = () => {
  const client = useQueryClient()
  const { post, get } = useNetwork()
  const { captureException } = useMonitoring()

  const { mutateAsync: createStack } = useMutation(
    ({ label, shouldPublish }: { label: string; shouldPublish?: boolean }) =>
      post<{ id: number; label: string; source: string }>('listings-stacks', {
        label,
        source: 'platform',
        shouldPublish,
      }),
    {
      onSuccess: () => client.invalidateQueries('stacks'),
      onError: (error: RequestError) => {
        captureException(error)
      },
    },
  )

  const { data: stacks = [] } = useQuery(
    'stacks',
    async () =>
      await get<Array<{ id: number; label: string }>>('listings-stacks').then(({ data }) => data),
    {
      staleTime: 3 * 60 * 1000,
    },
  )

  const getStackOptions = (usedStacks: Array<{ id: number; label: string }>) =>
    stacks.filter(({ id }) => !usedStacks.find(({ id: stackId }) => id === stackId))

  return { stacks, createStack, getStackOptions }
}
