import classNames from 'classnames'

import { Text } from 'components/Text'
import { useQueryParams } from 'shared/hooks'
import { useNavigate } from 'react-router-dom'

interface SwitchToLoginLinkProps {
  className?: string
}

export const SwitchToLoginLink = ({ className }: SwitchToLoginLinkProps) => {
  const navigate = useNavigate()
  const queryParams = useQueryParams()

  const handleSignIn = () => navigate(['/login', queryParams].join('?'))

  return (
    <div className={classNames('flex gap-1 justify-center', className)}>
      <Text size="small-body" weight="font-medium" className="text-neutral-darkest">
        Already have an account?
      </Text>
      <Text
        size="small-body"
        weight="font-medium"
        className="!text-info-medium cursor-pointer"
        onClick={handleSignIn}
      >
        Log in
      </Text>
    </div>
  )
}
