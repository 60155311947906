import { matchingEvents, profileEvents } from 'candidates-search/consts'
import { TimelineFilterValues } from 'candidates-search/domain'
import { ChangeLogType } from 'candidates-search/enums'

interface FetchTimelineRequest {
  profileId: string
  matchingIds: Optional<Array<string>>
  eventTypes: Optional<Array<ChangeLogType>>
  page: Optional<number>
  pageSize: Optional<number>
}

interface Props {
  profileId: string
  filter: TimelineFilterValues
  page: number
  pageSize: number
}
export class FetchTimelineDTO {
  constructor(private readonly props: Props) {}

  private get eventTypes() {
    const {
      filter: { selectedProfileEvents, selectedMatchingEvents },
    } = this.props
    const events: Array<ChangeLogType> = []

    selectedMatchingEvents.forEach((event) => {
      events.push(...matchingEvents[event])
    })

    selectedProfileEvents.forEach((event) => {
      events.push(...profileEvents[event])
    })

    return events
  }

  toJSON(): FetchTimelineRequest {
    return {
      profileId: this.props.profileId,
      matchingIds: this.props.filter.selectedMatchingIds,
      eventTypes: this.eventTypes,
      page: this.props.page,
      pageSize: this.props.pageSize,
    }
  }
}
