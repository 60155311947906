import { Text } from 'shared/components/atoms'
import { ChangeLog } from 'candidates-search/domain'

interface ListingDataProps {
  changeLog: ChangeLog
}

export const ListingData = ({ changeLog }: ListingDataProps) => {
  const listingTitle = changeLog.listing?.title
  const companyName = changeLog?.company?.name

  if (!changeLog.isMatchingRelated) return null

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-2">
        <Text size="text-xs" weight="font-normal" className="text-neutral-medium">
          Job listing:
        </Text>
        <Text size="text-xs" weight="font-normal" className="text-neutral-darkest">
          {listingTitle || 'No job listing'}
        </Text>
      </div>
      <div className="flex gap-2">
        <Text size="text-xs" weight="font-normal" className="text-neutral-medium">
          Company:
        </Text>
        <Text size="text-xs" weight="font-normal" className="text-neutral-darkest">
          {companyName || 'No company'}
        </Text>
      </div>
    </div>
  )
}
