import { Carousel as CarouselPrimitive } from 'react-responsive-carousel'

import './Carousel.scss'

interface Props {
  children: Array<React.ReactChild>
  className?: string
}

const Carousel = ({ children, className }: Props) => {
  return (
    <CarouselPrimitive
      className={className}
      autoPlay
      infiniteLoop
      showThumbs={false}
      showStatus={false}
      showIndicators
      showArrows={false}
    >
      {children}
    </CarouselPrimitive>
  )
}

export { Carousel }
