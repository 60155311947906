import { lazy } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from 'shared/hooks'
import { useBlacklist } from 'shared/hooks'
import { SecondaryButton } from 'shared/components/atoms'

import { Spinner } from 'shared/components/atoms'

const JobApplicationIllustration = lazy(() =>
  import('icons/ilustrations/job-application.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)

interface EmptyStateProps {
  isLoading: boolean
}

export const EmptyState = ({ isLoading }: EmptyStateProps) => {
  const { user } = useAuth()
  const { isUserBlocked } = useBlacklist(user)
  const navigate = useNavigate()

  const handleCreateListing = () => navigate('/listings/create')

  if (isLoading) return <Spinner />

  return (
    <div className="flex-grow flex flex-col items-center justify-center gap-4">
      <JobApplicationIllustration />
      <h2 className="text-xl font-medium">There's nothing here yet</h2>
      <p>All open listings will be shown here.</p>
      {!isUserBlocked && (
        <SecondaryButton className="mt-3" onClick={handleCreateListing}>
          Create a listing
        </SecondaryButton>
      )}
    </div>
  )
}
