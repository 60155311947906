import { Assessment, EnglishVetting } from 'modules/candidates/domain'
import { EnglishLevel, ProfileStatus, InstitutionTier } from 'modules/candidates/enums'
import { Answer } from 'job-opportunity/domain/answer.domain'
import { PaymentModel } from 'job-listing/enums'
import { JobOfferState } from 'modules/offers/enums'
import { AssessmentTag, ProficiencyTag } from 'modules/profiles/domain'
import { ProfileSections } from 'modules/profiles/enums'
import { ReferralStatus } from 'modules/referrals/enum'
import { QuestionSchema } from 'screening/domain'
import { PositionsSlug } from 'modules/shared/enums'
import { WorkExperience } from 'modules/work-experience'
import { JobOpportunityStatus } from 'job-opportunity/enums'
import {
  ScreeningAssignmentProgress,
  ScreeningRejectionReason,
} from 'modules/screening-assignment/enums'

export enum ProfileTagTypes {
  Github = 'github',
  Personal = 'personal',
}

export enum DegreeType {
  Bachelor = `Bachelor's Degree (or equivalent)`,
  Master = `Master's Degree (or equivalent)`,
  MBA = 'MBA (or equivalent)',
  Doctoral = 'Doctoral Degree (or equivalent)',
  HighSchool = 'Secondary / High School Diploma (or equivalent)',
  Other = 'Other degree',
}

export type ProfileTag = {
  label: string
  type: ProfileTagTypes
}

export type Role = {
  id: number
  label: string
}

export type Location = {
  city: string
  country: string
  placeId: string
  state: string
  utcOffsetMinutes: number
}

export type InterestRole = {
  id: number
  label: string
  roleType: PositionsSlug
}

export type Stack = {
  id: number
  label: string
}

export type StackExperience = {
  experience: number
  stack: Stack
}

export type ProfileCertification = {
  credentialId: string
  credentialUrl: string
  expireDate: string
  id: string
  institution: Institution
  issueDate: string
  name: string
  stacks: Array<Stack>
}

export type PublicPreferences = {
  longTerm: boolean
  shortTerm: boolean
  minFreelancingHoursPerWeek: number | null
  maxFreelancingHoursPerWeek: number | null
  noticePeriodDays: number | null
  status: ProfileStatus
  pausedUntil: string | null
}

export type ProfileFulfillment = {
  percentage: number
  rules: Array<{
    complete: boolean
    name: ProfileSections
    weight: number
    error?: boolean
  }>
}

export type MatchingForOffer = {
  id: string
  listingTitle: string
  hiringCost: number
  paymentModel: PaymentModel
}

export type DeclinationFeedback = {
  reasons: Array<string>
  otherReason?: string
  desiredPayRate?: number
  pausedDays?: number
  additionalNotes?: string
}

export type JobOpportunity = {
  id: string
  createdAt: string
  updatedAt: string
  submittedAt: string | null
  rejectedAt: string | null
  approvedAt: string | null
  declinedAt: string | null
  status: JobOpportunityStatus
  firstViewedAt: string | null
  startedFillingAt: string | null
  sharedCompanyInfoAt: string | null
  listingId: string
  questions: Array<QuestionSchema>
  screeningAnswers: Array<Answer>
  declinedReasons: Array<string>
  additionalNotes?: string
  expiredAt: string | null
  progress: ScreeningAssignmentProgress
  rejectionReasons: Array<ScreeningRejectionReason>
}

export type ProfileOverview = {
  id: string
  bioText: string
  firstName: string
  gitRepositoriesUrl: string
  interestRoles: Array<InterestRole>
  otherInterestRoles?: Array<string>
  lastName: string
  location: Location | null
  fullLocation: string
  yearlyRate: number | null
  hourlyRate: number | null
  personalSite: string
  linkedInUrl: string | null
  primaryRole: InterestRole | null
  primaryRoleExperience: number | null
  profileStacks: Array<StackExperience>
  selfIntroduction: string | undefined
  experiencesHistory: Array<WorkExperience>
  groupedExperiences: Array<GroupedExperience>
  educationHistory: Array<ProfileEducation>
  certifications: Array<ProfileCertification>
  photoUrl: string | null
  selfIntroductionAudioUrl: string | null
  submittedAt: string | null
  approvedByEmail: boolean | null
  rejectedByEmail: boolean | null
  introCallDone: boolean | null
  assessments: Array<Assessment>
  vettedByEmail: string | null
  onboardingCallDone: boolean
  status: ProfileStatus
  pausedUntil: string | null
  enableTechnicalAssessmentOptionsViewing: boolean
  isMockInterviewDone: boolean
  noticePeriodDays: number | null
  offerSent: boolean
  fulfillment: ProfileFulfillment
  whatsAppNumber: string | null
  lastWorkExperience: WorkExperience | null
  preferences: PublicPreferences | null
  englishLevel: EnglishLevel | null
  offers: Array<Offer>
  skippedEnglishVetting: boolean
  beginnerEnglishSpeaker: boolean
  fewYearsOfExperience: boolean
  outOfBounds: {
    byCountry: boolean
    byOpenToRole: boolean
    byReferralCountry: boolean
  }
  referralStatus: ReferralStatus | null
  referralDescription: string | null
  matchingsForOffer: Array<MatchingForOffer>
  englishVetting?: EnglishVetting
  declaredYearsOfExperience?: number
  invitedFriends: boolean
  canInviteFriends: boolean
  closeUrl?: string | null
  intercomUrl?: string | null
  fulfillmentStatus?: string
  closeLeadId?: string
  jobOpportunities: Array<JobOpportunity>
  assessmentTags: Array<AssessmentTag>
  proficiencies: Array<ProficiencyTag>
  tagsUpdatedAt: string | null
}

export type Offer = {
  id: string
  companyName: string
  expiresAt: string
  state: JobOfferState
}

export type Institution = {
  id: string
  label: string
  website: string | null
  tier: InstitutionTier
}

export type ProfileEducation = {
  id: string
  degreeType: DegreeType
  institution: Institution
  fieldOfStudy: string
  startDate: string
  endDate: string
}

export type GroupedExperience = {
  id: string
  companyName: string
  companyWebsite: string | null
  location: string
  startDate: string
  endDate: string
  experiences: Array<WorkExperience>
}

export type SortedExperiences = WorkExperience & {
  startDate: Date
  endDate: Date | undefined
}
