import { StriderUsersOnly } from 'components/Access/StriderUsersOnly'
import { Text } from 'shared/components/atoms'

interface TalentReviewBadgeProps {
  isTalentReview: boolean
}

export const TalentReviewBadge = ({ isTalentReview }: TalentReviewBadgeProps) => {
  if (!isTalentReview) return null

  return (
    <StriderUsersOnly>
      <Text
        weight="font-medium"
        size="text-xs"
        className="flex w-fit justify-center items-center px-2 py-1 rounded bg-info-lighter text-info-medium"
      >
        Talent Review
      </Text>
    </StriderUsersOnly>
  )
}
