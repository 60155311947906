import { lazy, useCallback, useRef } from 'react'
import classNames from 'classnames'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { DateObject, DatePickerRef, Plugin } from 'react-multi-date-picker'

import './DatePicker.scss'
import { useOnClickOutside } from 'shared/hooks'

const DatePanel = lazy(
  () => import(/* webpackPrefetch: true */ 'react-multi-date-picker/plugins/date_panel'),
)
const TimePicker = lazy(
  () => import(/* webpackPrefetch: true */ 'react-multi-date-picker/plugins/time_picker'),
)
const DatePickerElement = lazy(() => import(/* webpackPrefetch: true */ 'react-multi-date-picker'))

interface DatePickerProps {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<Record<string, any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<Record<string, any>>
  trigger: React.ReactNode
  showPanel?: boolean
  minDate?: Date
  disableWeekends?: boolean
  showTimePicker?: boolean
  multiplePicks?: boolean
  formatDateAsString?: boolean
}

export const DatePicker = ({
  name,
  setValue,
  watch,
  trigger,
  showPanel = false,
  minDate = new Date(),
  disableWeekends = false,
  showTimePicker = false,
  multiplePicks = true,
  formatDateAsString = false,
}: DatePickerProps) => {
  const dateTimeRef = useRef<DatePickerRef>(null)

  useOnClickOutside<DatePickerRef>(
    dateTimeRef,
    () => dateTimeRef.current && dateTimeRef.current.closeCalendar(),
  )

  const values = watch(name) as Array<Date>

  const plugins: Array<Plugin> = []
  if (showPanel) plugins.push(<DatePanel />)
  if (showTimePicker) plugins.push(<TimePicker hideSeconds />)

  const formatDate = (value: DateObject) => {
    return formatDateAsString ? value.toString() : value.toDate()
  }

  const handleChange = (value: DateObject | Array<DateObject>) => {
    setValue(name, Array.isArray(value) ? value.map(formatDate) : formatDate(value), {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const customizeDate = useCallback(
    (date: DateObject) => {
      const isWeekend = [0, 6].includes(date.weekDay.index)
      const prevDate = date.toDate() < minDate

      const disabled = (isWeekend && disableWeekends) || prevDate
      return {
        disabled: disabled,
        className: classNames({
          'text-neutral-medium': disabled,
        }),
      }
    },
    [minDate, disableWeekends],
  )

  return (
    <DatePickerElement
      ref={dateTimeRef}
      multiple={multiplePicks}
      format={showTimePicker ? 'MMM DD, YYYY hh:mm A' : 'MMM DD, YYYY'}
      plugins={plugins}
      render={(_: unknown, openCalendar: () => void) => {
        return (
          <button onClick={openCalendar} type="button">
            {trigger}
          </button>
        )
      }}
      value={values}
      onChange={handleChange}
      minDate={minDate}
      mapDays={({ date }) => customizeDate(date)}
    />
  )
}
