import { SecondaryButton } from 'shared/components/atoms'
import { ContentWrapper } from 'components/ContentWrapper'
import { PreviewLabel } from 'shared/components/atoms'
import { Text } from 'components/Text'
import { ProfileEmptyStateSection } from '../../ProfileEmptyStateSection'
import { UpsertButton } from '../../UpsertButton'
import { lazy } from 'react'

const BioIllustration = lazy(() =>
  import('icons/ilustrations/streamlinehq-going-to-work-on-bicycle.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)

interface BioContentProps {
  content: string | null
  onEdit: () => void
  readOnly: boolean
  placeholder?: JSX.Element
}

export const BioContent = ({ content, onEdit, readOnly, placeholder }: BioContentProps) => {
  if (content) {
    return (
      <ContentWrapper className="!mb-0">
        <PreviewLabel title="Bio">
          <div className="flex flex-row gap-6">
            <Text size="body" truncate={false}>
              {content}
            </Text>
            <UpsertButton onClick={onEdit} className="ml-auto" readOnly={readOnly} />
          </div>
        </PreviewLabel>
      </ContentWrapper>
    )
  }

  if (readOnly) {
    return placeholder ?? null
  }

  return (
    <ProfileEmptyStateSection
      title="Bio"
      content="Introduce yourself to potential employers. Talk about your professional achievements, motivations, and goals."
      actions={
        <SecondaryButton size="sm" onClick={onEdit} className="w-full sm:w-fit">
          Add bio
        </SecondaryButton>
      }
    >
      <BioIllustration className="h-[150px] min-w-[150px]" />
    </ProfileEmptyStateSection>
  )
}
