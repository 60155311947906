import { Recruiter } from 'modules/recruiter/recruiter'

export const nullRecruiter: Recruiter = {
  approved: false,
  firstName: '',
  id: '',
  lastName: '',
  slug: null,
  jobBoardURL: null,
  outOfBounds: {
    byCountry: false,
    byStriderStaff: false,
    byTaxInformation: false,
  },
}
