import { LayoutContext } from 'contexts/layout'
import { forwardRef, useContext, useEffect } from 'react'
import { ReactComponent as Logo } from 'icons/brand/brand-colored-positive.svg'
import classNames from 'classnames'

interface ContainerProps {
  children: React.ReactNode
  className?: string
  asideContent?: JSX.Element
  applyAsideGradient?: boolean
  asideContainerClassName?: string
  asideContentContainerClassName?: string
  showLogo?: boolean
}

export const Container = forwardRef<HTMLDivElement, ContainerProps>(function Container(
  {
    children,
    asideContent,
    applyAsideGradient = true,
    asideContainerClassName,
    asideContentContainerClassName,
    showLogo = true,
  }: ContainerProps,
  ref,
) {
  const { setFluidContainer, setDefaultContainer } = useContext(LayoutContext)

  useEffect(() => {
    setFluidContainer()
    return () => setDefaultContainer()
  }, [])

  return (
    <div
      className="flex flex-col-reverse lg:flex-row lg:flex-grow w-full min-h-screen relative animate-slideIn-right"
      ref={ref}
    >
      <div className="flex-grow lg:flex-grow-0 flex justify-center py-8 px-6 md:mx-0 lg:py-16 lg:px-24 lg:w-[calc(100%-480px)]">
        <div className="w-full flex-grow max-w-[800px]">{children}</div>
      </div>
      <aside
        className={classNames(
          'flex lg:flex-col justify-center items-center lg:justify-center w-full lg:h-screen',
          'bg-neutral-lightest bg-cover bg-left animate-slideIn-right lg:right-0 lg:w-[480px] lg:fixed',
          asideContainerClassName,
          {
            'bg-gradient-to-t from-neutral-lighter to-neutral-day': applyAsideGradient,
          },
        )}
      >
        {Boolean(showLogo) && <Logo className="h-7 w-auto z-10 m-4 lg:mx-0 lg:mt-16 lg:mb-0" />}
        <div className={classNames('flex h-full', asideContentContainerClassName)}>
          {asideContent}
        </div>
      </aside>
    </div>
  )
})
