import { ReactComponent as InfoIcon } from 'icons/info-circle.svg'
import { cn } from 'modules/shared'
import { Text } from 'shared/components/atoms'

interface InfoBannerProps {
  children: JSX.Element | string
  bgColor?: 'bg-neutral-lightest' | 'bg-info-lighter'
  iconColor?: 'stroke-neutral-medium' | 'stroke-info-medium'
  className?: string
}

export const InfoBanner = ({
  children,
  className,
  bgColor = 'bg-neutral-lightest',
  iconColor = 'stroke-neutral-medium',
}: InfoBannerProps) => {
  return (
    <div className={cn('flex gap-4 p-4 rounded-lg', bgColor, className)}>
      <InfoIcon className={cn('w-6 h-6 shrink-0', iconColor)} />
      <Text>{children}</Text>
    </div>
  )
}
