import { websiteSchema } from 'shared/hooks'
import { array, boolean, date, number, object, string } from 'yup'

export { WorkExperience } from './WorkExperience'

const isNotFuture = (value: Date | undefined) => {
  if (value) return value < new Date()
  return true
}

export const schema = object().shape({
  companyId: string(),
  companyName: string().required('Required field.'),
  companyWebsite: websiteSchema.required('Please inform the company website'),
  title: string().required('Required field.'),
  isManagement: boolean(),
  technologies: array()
    .of(
      object().shape({
        id: string(),
        label: string(),
      }),
    )
    .min(1, 'Choose at least one skill.'),
  location: string().required('Required field.').nullable(),
  isCurrently: boolean(),
  startDate: date()
    .required()
    .typeError('Enter a valid date.')
    .min('1960-01-01', 'The start date should be later than 01/01/1960')
    .test('is_not_future', 'The start date must be before today.', isNotFuture),
  endDate: date()
    .when('isCurrently', {
      is: false,
      then: date().required('Required field.').typeError('Enter a valid date.'),
      otherwise: date().nullable(),
    })
    .test('is_not_future', 'The end date must be before today.', isNotFuture)
    .test('greater_than_start', 'The end date must be after the start date.', function (value) {
      if (value) return value > this.parent.startDate
      return true
    }),
  description: string()
    .required('Required field.')
    .test('empty_content', 'Required field.', function (value) {
      return Boolean(value?.replace('<p></p>\n', ''))
    }),
  numberOfManagedPeople: number().when('isManagement', {
    is: true,
    then: number().min(1, 'Min expected value: 1').required('Required field.'),
    otherwise: number(),
  }),
  percentageOfWorkBuilding: number().when('isManagement', {
    is: true,
    then: number().min(0, 'Min expected value: 0').max(90, 'Max expected value: 90%'),
    otherwise: number(),
  }),
})

export const emptyState = {
  companyId: '',
  companyName: '',
  companyWebsite: '',
  title: '',
  technologies: [],
  location: '',
  isCurrently: false,
  startDate: null,
  endDate: null,
  description: '',
  numberOfManagedPeople: 0,
  percentageOfWorkBuilding: 0,
  isManagement: false,
}
