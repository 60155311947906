import { useEffect } from 'react'
import { useRedirectToReferrals } from './useRedirectToReferrals'

const landingLocationKey = 'landingLocation'
const defaultUrl = '/'

export const useLandingTracking = () => {
  const { onSessionStart } = useRedirectToReferrals()

  const getCurrentUrl = () => {
    const { pathname, search, hash } = new URL(window.location.href)

    return [pathname, search, hash].join('')
  }

  const getLandingLocation = () => {
    return window.sessionStorage.getItem(landingLocationKey) || defaultUrl
  }

  const getLandingLocationParams = () => {
    const landingLocation = getLandingLocation()
    const queryString = landingLocation.substring(landingLocation.indexOf('?'))

    return new URLSearchParams(queryString)
  }

  useEffect(() => {
    const landingLocation = window.sessionStorage.landingLocation || getCurrentUrl()
    window.sessionStorage.setItem(landingLocationKey, landingLocation)
    onSessionStart(landingLocation)
  }, [])

  const resetLandingLocation = () => window.sessionStorage.removeItem(landingLocationKey)

  const isSdrFlow = Boolean(getLandingLocation().match(/candidates/))

  return { getLandingLocation, resetLandingLocation, isSdrFlow, getLandingLocationParams }
}
