import { useEffect, useMemo } from 'react'

import {
  FieldHint,
  Label,
  PrimaryButton,
  TertiaryButton,
  Text,
  Textarea,
} from 'shared/components/atoms'
import { Modal } from 'shared/components/molecules'
import { TalentReviewToggle, ListingWithMatchingsBanner } from 'job-listing/components'

import { useForm } from 'shared/hooks'
import { useListingSettings } from '../../hooks'

import { ListingSettings } from 'job-listing/domains'
import { ListingInfo } from 'job-listing/domains'

import { schema } from '.'
import { PaymentModel } from 'job-listing/enums'
import { EnableOfferMaking, HourlyRate, VisiblePayRate } from '..'

interface ListingSettingsModalProps {
  listing: ListingInfo
  isOpen: boolean
  onClose: () => void
}

export const ListingSettingsModal = ({ listing, isOpen, onClose }: ListingSettingsModalProps) => {
  const defaultValues = useMemo(
    () => ({
      jobOffersEnabled: Boolean(listing.jobOffersEnabled),
      hiringCostsVisible: Boolean(listing.hiringCostsVisible),
      hourlyRatesEnabled: Boolean(listing.hourlyRatesEnabled),
      internalNotes: listing.internalNotes,
      matchingsChecklist: listing.matchingsChecklist,
      isTalentReview: Boolean(listing.isTalentReview),
    }),
    [listing],
  )

  const { getValues, setValue, watch, handleSubmit, reset, register } = useForm({
    schema,
    defaultValues: defaultValues,
  })

  const { updateSettings, isUpdating } = useListingSettings(listing.id)

  const handleCloseListingSettings = () => {
    reset(defaultValues)
    onClose()
  }

  const onSubmit = handleSubmit(() => {
    const values = getValues() as ListingSettings
    updateSettings(values).then(() => handleCloseListingSettings())
  })

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues])

  const hasMatchings = listing.hourlyRatesEnabled
    ? listing.hasHourlyMatchings
    : listing.hasMonthlyMatchings
  const isPaymentModelChanged = Boolean(watch('hourlyRatesEnabled')) !== listing.hourlyRatesEnabled

  return (
    <Modal
      mobilePositioning="bottom"
      titleContainerClassName="!py-6 !px-10"
      title="Job listing settings"
      open={isOpen}
      handleClose={handleCloseListingSettings}
      contentContainerClassName="!p-10"
      content={
        <form id="listing-settings-form" onSubmit={onSubmit} className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
              External ID
            </Text>
            <Text size="text-base" weight="font-medium">
              {listing.externalId}
            </Text>
          </div>

          <EnableOfferMaking listing={listing} watch={watch} setValue={setValue} />
          <VisiblePayRate listing={listing} watch={watch} setValue={setValue} />
          <HourlyRate watch={watch} setValue={setValue} />
          <TalentReviewToggle watch={watch} setValue={setValue} />
          <ListingWithMatchingsBanner
            initialPaymentModel={
              listing.hourlyRatesEnabled ? PaymentModel.Hourly : PaymentModel.Monthly
            }
            visible={hasMatchings && isPaymentModelChanged}
          />
          <div>
            <Label htmlFor="matchingsChecklist">Matchings checklist</Label>
            <Textarea register={register} name="matchingsChecklist" omitErrors />
            <FieldHint>Checklist template for CRM opportunities records.</FieldHint>
          </div>
          <div>
            <Label htmlFor="internalNotes">Internal notes</Label>
            <Textarea register={register} name="internalNotes" omitErrors />
          </div>
        </form>
      }
      footer={
        <div className="flex-grow w-full flex flex-row gap-4 justify-end">
          <TertiaryButton onClick={handleCloseListingSettings}>Cancel</TertiaryButton>
          <PrimaryButton type="submit" form="listing-settings-form" isLoading={isUpdating}>
            Save changes
          </PrimaryButton>
        </div>
      }
    />
  )
}
