import { useEffect, useState } from 'react'

import { CollapsedMenu, CollapsedMenuItem } from 'shared/components/molecules'
import { JobListingPauserDialog } from 'job-listing/exports'

import { AccountClaim, useAuth } from 'shared/hooks'

import { ListingStatus } from 'job-listing/enums'
import { HomepageListing } from 'job-listing/dtos'

import { ReactComponent as DotsIcon } from 'icons/dots.svg'

interface ContextualMenuProps {
  listing: HomepageListing
  onPaused: () => void
}

export const ContextualMenu = ({ listing, onPaused }: ContextualMenuProps) => {
  const [isAlertDialogOpen, setAlertDialogOpen] = useState<boolean>(false)

  const openDialog = () => setAlertDialogOpen(true)
  const closeDialog = () => setAlertDialogOpen(false)

  const { user } = useAuth()
  const [status, setStatus] = useState<ListingStatus>()

  useEffect(() => setStatus(listing.status), [listing])

  const menuItems: Array<CollapsedMenuItem> = [
    {
      label: 'Pause',
      callback: openDialog,
      visible:
        status === ListingStatus.Active && user.claims.includes(AccountClaim.PauseJobListings),
    },
  ].filter(({ visible }) => visible)

  if (!menuItems.length) return null

  return (
    <>
      <CollapsedMenu triggerElement={<DotsIcon className="!w-6 !h-6" />} items={menuItems} />
      {isAlertDialogOpen && (
        <JobListingPauserDialog
          listingId={listing.id}
          isTalentReview={listing.isTalentReview}
          isOpen={isAlertDialogOpen}
          closeDialog={closeDialog}
          onPause={onPaused}
        />
      )}
    </>
  )
}
