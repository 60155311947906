import { useMutation, useQuery, useQueryClient } from 'react-query'

import { useAuth, useMonitoring, useNetwork, useToast } from 'shared/hooks'

export const useOrganization = (profileId?: string, loadOrganizations = true) => {
  const {
    user: { isJobSeekerProfilesManager },
  } = useAuth()

  const client = useQueryClient()
  const { post, get, put } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()

  const { mutateAsync: publishOrganization } = useMutation(
    (organizationId: string) => post(`profile/${profileId}/organization/${organizationId}/publish`),
    {
      onError: (error: RequestError) => {
        toastError('An error ocurred when trying to publish the organization.')
        captureException(error)
      },
    },
  )

  const { mutateAsync: upsertOrganization } = useMutation(
    (data: { id: string | null; name: string; website: string | null; publish: boolean }) => {
      if (data.id)
        return put(`profile/${profileId}/organization/${data.id}`, {
          name: data.name,
          website: data.website,
        })

      return post<{ id: string; label: string; website: string }>(
        `profile/${profileId}/organization`,
        {
          name: data.name,
          website: data.website,
        },
      )
    },
    {
      onSuccess: ({ data }, { id, publish }) => {
        client.invalidateQueries('organizations')
        if (isJobSeekerProfilesManager && publish) publishOrganization(data.id || id)
      },
      onError: (error: RequestError) => {
        toastError('Error when upserting the organization record: ' + error.response?.data.message)
        captureException(error)
      },
    },
  )

  const { data: organizations = [] } = useQuery(
    'organizations',
    async () =>
      await get<Array<{ id: string; name: string; website: string; authorId?: number }>>(
        `profile/${profileId}/organization`,
      ).then(({ data }) => data),
    {
      enabled: loadOrganizations && Boolean(profileId),
      initialData: [],
    },
  )

  return { upsertOrganization, organizations }
}
