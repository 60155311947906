import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Spinner } from 'shared/components/atoms'
import { SignInForm, SidePanel } from 'authentication/components'

import { useLandingTracking, useRedirectToReferrals } from 'shared/hooks'

import { RouterContext } from 'contexts/router'

const SignInPage = () => {
  const route = useContext(RouterContext)
  const { isSdrFlow } = useLandingTracking()
  const navigate = useNavigate()
  const { shouldRedirectToReferrals } = useRedirectToReferrals()

  useEffect(() => {
    if (isSdrFlow) navigate('/signup?redirectUrl=/employer-onboarding')
  }, [isSdrFlow])

  if (isSdrFlow) return <Spinner />

  return (
    <div className="flex flex-col-reverse lg:flex-row min-h-screen">
      <main className="flex flex-col flex-grow justify-center items-center bg-white">
        <SignInForm redirectUrl={shouldRedirectToReferrals ? '/referrals/' : route.from} />
      </main>
      <SidePanel />
    </div>
  )
}

export { SignInPage }
