import { PrimaryButton, TertiaryButton } from 'shared/components/atoms'
import { ContentWrapper } from 'components/ContentWrapper'
import { Label } from 'shared/components/atoms'
import { Modal } from 'shared/components/molecules'
import { StyledSelect } from 'shared/components/atoms'

import { NoticePeriod } from './components/NoticePeriod'
import { JobTypes } from './components/JobTypes'
import { NotWillingIndustries } from './components/NotWillingIndustries'
import { CompanySizes } from './components/CompanySizes'

import { useForm } from 'shared/hooks'
import { useUpdatePreferences } from 'pages/JobSeekerProfile/hooks'

import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { Preferences as PreferencesDomain } from 'modules/profiles/profile-preferences'
import { availabilityOptions } from './consts'

import { defaultValues, schema } from '.'
import { AllowAdvertisement } from './components/AllowAdvertisement/AllowAdvertisement'

interface PreferencesProps {
  profile: ProfileOverview
  preferences?: PreferencesDomain
  handleClose: () => void
}

export const Preferences = ({ profile, preferences, handleClose }: PreferencesProps) => {
  const { register, watch, setValue, handleSubmit, getValues, errors, isDirty } = useForm({
    schema,
    defaultValues: preferences || defaultValues,
  })

  const { updatePreferences, isLoading } = useUpdatePreferences(profile.id)

  const onSubmit = handleSubmit(() => {
    const values = getValues() as PreferencesDomain
    updatePreferences(values).then(() => handleClose())
  })

  return (
    <Modal
      withBorders
      showClose
      handleClose={handleClose}
      showDismissalConfirmation={isDirty}
      title="Job preferences"
      content={
        <form id="preferences-form" onSubmit={onSubmit}>
          <ContentWrapper isForm className="!mb-3">
            <Label htmlFor="availability" size="text-sm">
              Job search availability
            </Label>
            <StyledSelect
              id="availability"
              register={register}
              name="availability"
              options={availabilityOptions}
              watch={watch}
              setValue={setValue}
              placeholder="Select"
            />
          </ContentWrapper>
          <NoticePeriod register={register} watch={watch} />
          <JobTypes register={register} watch={watch} setValue={setValue} errors={errors} />
          <NotWillingIndustries preferences={preferences} watch={watch} setValue={setValue} />
          <CompanySizes register={register} watch={watch} errors={errors} />
          <AllowAdvertisement watch={watch} setValue={setValue} />
        </form>
      }
      footer={
        <>
          <TertiaryButton size="md" onClick={handleClose} className="w-full md:w-fit">
            Cancel
          </TertiaryButton>
          <PrimaryButton
            size="md"
            type="submit"
            form="preferences-form"
            isLoading={isLoading}
            className="w-full md:w-fit"
          >
            Save
          </PrimaryButton>
        </>
      }
    />
  )
}
