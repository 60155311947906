import { useContext, useMemo } from 'react'
import { VettingPackageResponse } from 'vetting/domain'
import { CandidateContext } from 'contexts/candidate'
import { Tasks } from '../../domain'
import { Tasks as TasksEnum } from 'modules/candidates/enums'

export const usePendingTasks = (vetting?: VettingPackageResponse) => {
  const { candidate } = useContext(CandidateContext)

  const { pendingOffers, pendingTasks, pendingLiveInterview } = useMemo(
    () => new Tasks(candidate, [], vetting),
    [candidate, vetting, []],
  )

  const holdJobOpportunityTasks = pendingTasks.filter((task) => task === TasksEnum.EnglishVetting)

  return { pendingOffers, pendingTasks, pendingLiveInterview, holdJobOpportunityTasks }
}
