import { Label, SelectTags } from 'shared/components/atoms'
import { FormControl, useStacks } from 'shared/hooks'

interface Props {
  formControl: FormControl
  name: string
  error?: string
}

export const RequiredSkills = ({ formControl, name, error }: Props) => {
  const { watch, setValue } = formControl
  const { stacks: stackOptions, createStack } = useStacks()
  const stacks = watch(name)

  return (
    <>
      <Label htmlFor={name} size="text-sm">
        Desired skills
      </Label>
      <SelectTags
        name={name}
        options={stackOptions}
        selectedOptions={stacks}
        setValue={setValue}
        watch={watch}
        allowCreation
        error={error}
        containerClassName="mb-10"
        onCreateOption={(label) => createStack({ label })}
      />
    </>
  )
}
