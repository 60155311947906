import classNames from 'classnames'
import { Text } from 'components/Text'
import { ReactComponent as CheckIcon } from 'icons/check-circle.svg'

interface Props {
  isSelected: boolean
  icon: React.ReactElement
  title: string
  description: string
  onClick: () => void
}

export const Option = ({ isSelected, icon, title, description, onClick }: Props) => {
  return (
    <div
      className={classNames('flex flex-grow py-4 px-6 gap-6 rounded-lg items-center', {
        'border-2 border-neutral-darkest pointer-events-none': isSelected,
        'border border-neutral-light cursor-pointer': !isSelected,
      })}
      onClick={onClick}
    >
      <div className="flex items-center justify-center h-14 w-14 rounded-full bg-neutral-lightest">
        {icon}
      </div>
      <div className="flex flex-grow justify-between items-center">
        <div>
          <Text size="body" weight="font-medium" className="text-neutral-darkest">
            {title}
          </Text>
          <Text size="small-body" weight="font-medium" className="text-neutral-dark">
            {description}
          </Text>
        </div>
        {Boolean(isSelected) && <CheckIcon className="fill-neutral-darkest w-6 h-6" />}
      </div>
    </div>
  )
}
