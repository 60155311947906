import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { useMemo } from 'react'
import { useFetchRecordings } from './useFetchRecordings'
import { EnglishVettingStep as Steps } from 'vetting/enums'

export const useRecordedVideosCount = (profile: ProfileOverview) => {
  const { fetchedRecordings } = useFetchRecordings(profile)

  const videosRecorded = useMemo<number>(() => {
    let recordings = 0
    if (!fetchedRecordings) return recordings

    if (fetchedRecordings[Steps.AboutCandidate]) recordings++
    if (fetchedRecordings[Steps.TechnicalChallenges]) recordings++
    if (fetchedRecordings[Steps.FutureInterviews]) recordings++

    return recordings
  }, [fetchedRecordings])

  return videosRecorded
}
