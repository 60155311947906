import { RecordingStep, Steps } from './enums'

interface StepData {
  title: string
  subtitle: string
  dos: Array<string>
  boost: Array<string>
  donts: Array<string>
  scripts: Array<{ title: string; content: string }>
  minLengthInSeconds: number
  maxLengthInSeconds: number
}

export const STEPS = [
  Steps.HardwareCheckup,
  Steps.AboutCandidate,
  Steps.TechnicalChallenges,
  Steps.FutureInterviews,
  Steps.Submit,
]

export const STEP_DATA = {
  [Steps.AboutCandidate]: {
    title: 'Introduce yourself',
    subtitle: 'Read our best practices to introduce yourself as done in all interviews.',
    dos: [
      'Clean background and good lighting',
      'Your first name',
      'Your professional title (e.g., “Software Developer)',
      'How many years you have been working in the software industry',
      'Career goals and the reasoning behind them',
      'What makes you unique (hard or soft skills)',
      'What you are learning right now (in your career or new skills)',
    ],
    boost: [
      'Be specific and showcase your expertise',
      "Speak naturally and don't read a script",
      'Be mindful of your surroundings and the quality of your video; your future employers will watch it before asking you for an interview.',
    ],
    donts: [
      'Telling personal information (age, marital status, family situation, etc.)',
      'Telling irrelevant information (where you live, pet name, hobbies, etc.)',
      'Mentioning English practice as your career goal',
      'Mentioning financial motivation as your career goal',
      "Reciting the script. It'll sound artificial to companies",
      'Recording this video in a room with poor lighting, or a noisy place',
      'Recording this video vertically (like an Instagram story)',
      'Speaking any language other than English',
    ],
    scripts: [
      {
        title: 'Software Developers and related roles',
        content: `Hello, my name is Bruno and I am a software developer with over 10 years of experience in the field. I received my Bachelor's degree in computer science and have always been passionate about using technology to solve complex problems and make a positive impact in the world.

Throughout my career, I have had the opportunity to work on a wide range of projects, ranging from developing web applications to implementing machine learning models. My main tech stack includes Python, Django, Javascript, React, and SQL databases, but I am always eager to learn and explore new technologies to find the best solution for each project.

I am always looking for new opportunities to use my skills and expertise to make a difference and I am particularly passionate about using machine learning to solve real-world problems. Whether it's developing new software solutions or implementing cutting-edge machine learning models, I am always excited to take on new challenges and make a positive impact.

I look forward to working with you and helping you achieve your goals.`,
      },
      {
        title: 'Product/UX/UI designers and related roles',
        content: `My name is Camila, and I have been working with product design for over 10 years. I received my Bachelor's degree in Design from Universidade Estadual Paulista, which is one of the top 2% universities in Brazil. I have always been passionate about solving problems through a user-centered approach. Throughout my career, I have had the opportunity to work on a variety of projects ranging in health, financial and carrier markets. 

One of my primary skills as a product designer is user centered design. I have a strong understanding of prototyping and research, which allows me to bring a holistic approach to my work. 

I believe that understanding the user's needs and creating functional and aesthetically pleasing designs are key to creating successful products. 

I am excited to bring my skills and passion to help you build the future of your company`,
      },
    ],
    minLengthInSeconds: Number(
      process.env.REACT_APP_ENGLISH_CHECK_ABOUT_CANDIDATE_MIN_LENGTH ?? '60',
    ),
    maxLengthInSeconds: Number(
      process.env.REACT_APP_ENGLISH_CHECK_ABOUT_CANDIDATE_MAX_LENGTH ?? '90',
    ),
  },
  [Steps.TechnicalChallenges]: {
    title: 'Past technical challenges ',
    subtitle: 'Answer the questions below related to past challenges.',
    dos: [
      'Tell us the most significant technical challenge you have ever faced',
      'How did you overcome the challenge?',
      'What were the achieved results?',
      'Were there learned lessons for you?',
      'What would you do differently to resolve the same problem?',
    ],
    boost: [
      'Be specific and showcase your expertise',
      'Tell the whole story to show how good you were at resolving the problem',
      'Share results achieved and the business impact of your solution ',
    ],
    donts: [
      "Avoid reciting the script. It'll sound artificial to companies",
      'Do not speak any language other than English',
      'Do not record this video vertically (like an Instagram story), in a room with poor lighting, or a noisy place.  ',
    ],
    scripts: [],
    minLengthInSeconds: Number(
      process.env.REACT_APP_ENGLISH_CHECK_TECHNICAL_CHALLENGES_MIN_LENGTH ?? '60',
    ),
    maxLengthInSeconds: Number(
      process.env.REACT_APP_ENGLISH_CHECK_TECHNICAL_CHALLENGES_MAX_LENGTH ?? '180',
    ),
  },
  [Steps.FutureInterviews]: {
    title: 'For your future interviews',
    subtitle: 'Prepare questions for your future interviewers.',
    dos: [
      'Ask your interviewers three open-ended questions that you would do in your live interview with them',
      'Explain the reasoning behind each question',
    ],
    boost: [
      'Questions about team culture, product, industry, technical or business challenges have higher engagement than others',
      'Ask the questions one by one and organize your thoughts and speaking',
    ],
    donts: [
      'Avoid asking questions about salary, time off, lunchtime, company size, insurance, equity, etc. You can know those things when you receive your first interview request.',
    ],
    scripts: [],
    minLengthInSeconds: Number(
      process.env.REACT_APP_ENGLISH_CHECK_FUTURE_REVIEWS_MIN_LENGTH ?? '60',
    ),
    maxLengthInSeconds: Number(
      process.env.REACT_APP_ENGLISH_CHECK_FUTURE_REVIEWS_MAX_LENGTH ?? '120',
    ),
  },
} as Record<RecordingStep, StepData>
