import { ReactNode, MouseEvent } from 'react'

import { Candidate, Filter, SearchMetadata } from 'candidates-search/domain'
import { COUNTRY_FLAGS } from 'modules/shared/consts'
import { PayRateType } from 'modules/shared/enums'
import { englishResultLabel } from 'vetting/enums'
import {
  EnglishVettingProgress,
  InstitutionTier,
  institutionTierLabels,
  selfEvaluationLabel,
} from 'modules/candidates/enums'
import { HighlightMetadata } from 'candidates-search/enums/search-metadata.enum'

import { ReactComponent as CompensationIcon } from 'icons/bank-note-03.svg'
import { ReactComponent as CalendarIcon } from 'icons/calendar-date.svg'
import { ReactComponent as FlagIcon } from 'icons/flag-01.svg'
import { ReactComponent as AlertIcon } from 'icons/alert-triangle.svg'
import { ReactComponent as CheckIcon } from 'icons/check-verified-02.svg'
import { ReactComponent as FailIcon } from 'icons/x-square.svg'
import { ReactComponent as MailIcon } from 'icons/mail-01.svg'
import { ReactComponent as TierOneStarIcon } from 'icons/star-06.svg'
import { ReactComponent as TierTwoStarIcon } from 'icons/star-04.svg'
import { ReactComponent as InstitutionIcon } from 'icons/building-08.svg'
import { ReactComponent as GraduationIcon } from 'icons/graduation-hat-01.svg'
import { ReactComponent as CopyIcon } from 'icons/copy.svg'
import { ReactComponent as PhoneIcon } from 'icons/phone.svg'
import { ReactComponent as MessageIcon } from 'icons/message-square-02.svg'
import { AccountClaim } from 'shared/hooks'
import { Link, Paragraph, Tooltip } from 'shared/components/atoms'

interface Info {
  icon: JSX.Element
  text: ReactNode
  warning?: boolean
  url?: Nullable<string>
}

export class Infos {
  constructor(
    private readonly candidate: Candidate & SearchMetadata,
    private readonly filter: Filter,
    private readonly formatCurrency: (value: number) => string,
    private readonly claims: Array<AccountClaim>,
    private readonly toastSuccess: (message: string) => void,
  ) {}

  private get country(): Info {
    const country = this.candidate.location.country
    return {
      icon: <span className="relative -top-1">{COUNTRY_FLAGS[country] || '🌍'}</span>,
      text: country,
    }
  }

  private get compensation(): Info {
    const isMonthlyRateSelected = this.filter.payRate.type === PayRateType.Monthly
    const minRate = isMonthlyRateSelected
      ? this.candidate.monthlyRate.min
      : this.candidate.hourlyRate.min

    return {
      icon: <CompensationIcon className="w-4 h-4 stroke-neutral-dark shrink-0" />,
      text: `Min comp: ${this.formatCurrency(minRate)} ${
        this.candidate.openToNegotiation ? '(flexible)' : ''
      }`,
    }
  }

  private get experience(): Info {
    const { experienceTimeLabel } = this.candidate
    return {
      icon: <CalendarIcon className="w-4 h-4 stroke-neutral-dark shrink-0" />,
      text: `${experienceTimeLabel[0].toUpperCase() + experienceTimeLabel.slice(1)}`,
    }
  }

  private get englishLevelText() {
    const { englishLevel } = this.candidate

    if (englishLevel?.level) {
      return englishResultLabel[englishLevel.level]
    }

    if (englishLevel?.vettingProgress === EnglishVettingProgress.RecordAgain) {
      return 'New video requested'
    }

    if (englishLevel?.isInProgress) {
      return 'Waiting for evaluation'
    }

    if (englishLevel?.isOnHold) {
      return 'Evaluation on hold'
    }

    return 'Pending English Check'
  }

  private get englishLevel(): Info {
    const icon = this.candidate.englishLevel?.isResultAvailable ? (
      <FlagIcon className="w-4 h-4 stroke-neutral-dark shrink-0" />
    ) : (
      <AlertIcon className="w-4 h-4 stroke-warning-dark shrink-0" />
    )

    return {
      icon,
      warning: !this.candidate.englishLevel?.isResultAvailable,
      text: this.englishLevelText,
      url: this.claims.includes(AccountClaim.EnglishCheckViewEvaluation)
        ? this.candidate.englishLevel?.evaluationUrl
        : undefined,
    }
  }

  private get selfDeclaredEnglishLevel(): Info | undefined {
    const { englishLevel } = this.candidate

    if (englishLevel?.isResultAvailable) return

    return {
      icon: <FlagIcon className="w-4 h-4 stroke-neutral-dark shrink-0" />,
      text: englishLevel?.declaredLevel
        ? selfEvaluationLabel[englishLevel?.declaredLevel]
        : 'Unavailable',
    }
  }

  private get staIcon() {
    const assessment = this.candidate.assessments[0]

    if (!assessment) {
      return <AlertIcon className="w-4 h-4 stroke-warning-dark shrink-0" />
    }

    if (assessment.passed) {
      return <CheckIcon className="w-4 h-4 stroke-neutral-dark shrink-0" />
    }

    if (assessment.failed) {
      return <FailIcon className="w-4 h-4 stroke-warning-dark shrink-0" />
    }

    return <AlertIcon className="w-4 h-4 stroke-warning-dark shrink-0" />
  }

  private get staText() {
    const assessment = this.candidate.assessments[0]

    if (!assessment) {
      return 'Pending STA'
    }

    if (assessment.inProgress) {
      return 'STA in progress'
    }

    if (assessment.passed) {
      return 'Passed STA'
    }

    if (assessment.failed) {
      return 'Failed STA'
    }

    return 'Pending STA'
  }

  private get sta(): Info {
    return {
      icon: this.staIcon,
      warning: !this.candidate.assessments[0]?.passed,
      text: this.staText,
      url: this.candidate.assessments[0]?.url,
    }
  }

  private get email(): Optional<Info> {
    const { highlights } = this.candidate
    if (!highlights) return

    if (!highlights[HighlightMetadata.Email]) return

    const text = highlights[HighlightMetadata.Email][0]
    const formattedText = text
      .replaceAll('<em>', '')
      .replaceAll('</em>', '')
      .replaceAll('<p>', '')
      .replaceAll('</p>', '')
      .replaceAll('<br>', '')

    const handleClick = (email: string) => (event: MouseEvent) => {
      event.stopPropagation()
      navigator.clipboard.writeText(email)
      this.toastSuccess('Email copied to clipboard.')
    }

    return {
      icon: <MailIcon className="w-4 h-4 stroke-neutral-dark shrink-0 " />,
      text: (
        <div className="flex items-center gap-1 group">
          <Tooltip childrenClassName="text-ellipsis overflow-hidden" content={formattedText}>
            <Paragraph size="body-sm" className="text-neutral-dark">
              {formattedText}
            </Paragraph>
          </Tooltip>
          <Tooltip content="Copy to clipboard">
            <CopyIcon
              className="w-4 h-4 neutral-lightest shrink-0 cursor-pointer group-hover:stroke-neutral-dark"
              onClick={handleClick(formattedText)}
            />
          </Tooltip>
        </div>
      ),
    }
  }

  private get educationRecord() {
    if (!this.filter?.educationDegree) return

    const education =
      this.candidate.educations.find(
        ({ degreeType }) => degreeType === this.filter.educationDegree,
      ) || null

    return education
  }

  private get institutionName(): Info | undefined {
    if (!this.educationRecord) return

    return {
      icon: <InstitutionIcon className="w-4 h-4 stroke-neutral-dark shrink-0" />,
      text: this.educationRecord.institutionName,
    }
  }

  private get institutionTier(): Info | undefined {
    if (!this.educationRecord) return

    const { institutionTier } = this.educationRecord

    if (![InstitutionTier.One, InstitutionTier.Two].includes(institutionTier)) return

    return {
      icon:
        institutionTier === InstitutionTier.One ? (
          <TierOneStarIcon className="stroke-warning-dark w-4 h-4 flex-shrink-0" />
        ) : (
          <TierTwoStarIcon className="stroke-neutral-medium w-4 h-4 flex-shrink-0" />
        ),
      text: institutionTierLabels[institutionTier],
      warning: institutionTier === InstitutionTier.One,
    }
  }

  private get degree(): Info | undefined {
    if (!this.educationRecord) return

    return {
      icon: <GraduationIcon className="w-4 h-4 stroke-neutral-dark shrink-0" />,
      text: this.educationRecord.degreeType,
    }
  }

  private get phoneNumber(): Optional<Info> {
    const numbers = [this.candidate.whatsAppNumber ?? '']
      .concat(this.candidate.closePhoneNumbers)
      .filter((valid) => valid)
      .map((number) => [number.replace(/[^0-9]+/g, ''), number])
      .filter(([value], index, all) => all.findIndex(([phone]) => phone === value) === index)

    if (numbers.length === 0) return

    const handleClick = (number: string) => (event: MouseEvent) => {
      event.stopPropagation()
      navigator.clipboard.writeText(number)
      this.toastSuccess('WhatsApp number copied to clipboard.')
    }

    return {
      icon: <PhoneIcon className="w-4 h-4 stroke-neutral-medium shrink-0" />,
      text: (
        <ul>
          {numbers.map(([number, formatted]) => (
            <li key={number} className="flex gap-4 mr-4 items-center group">
              <Link url={'https://wa.me/' + number} className="text-neutral-dark">
                {formatted}
              </Link>
              <Tooltip content="Copy to clipboard">
                <CopyIcon
                  className="w-4 h-4 neutral-lightest shrink-0 group-hover:stroke-neutral-dark"
                  onClick={handleClick(formatted)}
                />
              </Tooltip>
            </li>
          ))}
        </ul>
      ),
    }
  }

  private get mockInterview(): Optional<Info> {
    if (!this.candidate.mockInterviewDone) return

    return {
      icon: <MessageIcon className="w-4 h-4 stroke-neutral-dark shrink-0" />,
      text: 'Mock interview: done',
    }
  }

  get infos() {
    return [
      this.country,
      this.compensation,
      this.experience,
      this.englishLevel,
      this.selfDeclaredEnglishLevel,
      this.sta,
      this.mockInterview,
      this.institutionName,
      this.institutionTier,
      this.degree,
      this.email,
      this.phoneNumber,
    ].filter((info) => info) as Array<Info>
  }
}
