import { useContext, useState } from 'react'

import {
  Label,
  Paragraph,
  RichTextEditor,
  SecondaryButton,
  SelectTags,
  Suggestion,
  Text,
  useRichText,
} from 'shared/components/atoms'
import { FormControl, UserContext, useStacks } from 'shared/hooks'
import { ReactComponent as EditIcon } from 'icons/edit.svg'
import { cn } from 'modules/shared'

interface MatchBriefingProps {
  formControl: FormControl
  candidateFirstName: string
  showDescription?: boolean
  showEditButton?: boolean
  showOptionalLabel?: boolean
  isLoading?: boolean
}

export const MatchBriefing = ({
  formControl,
  candidateFirstName,
  showDescription,
  showEditButton,
  showOptionalLabel,
  isLoading,
}: MatchBriefingProps) => {
  const [disableField, setDisableField] = useState(showEditButton)
  const {
    user: { isStriderStaff },
  } = useContext(UserContext)

  const { setValue, watch, getValues } = formControl
  const { stacks, createStack } = useStacks()

  const handleCreateStack = (label: string) => createStack({ label, shouldPublish: true })
  const handleEditBriefing = () => setDisableField(false)

  const { checkIsEmpty } = useRichText()
  const briefingValue = getValues('briefing')

  const selectedSkills = watch('highlightedSkills') as Array<Suggestion>

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <Text size="text-xl">Match briefing</Text>
        {showDescription && (
          <Text size="text-sm" className="text-neutral-dark">
            Help clients understanding why {candidateFirstName} is a good match. Share your
            reasoning for matching his profile with the open role and highlight his skill that most
            matches with the role.
          </Text>
        )}
      </div>
      <div className="flex flex-col">
        <Label
          htmlFor="briefing"
          size="text-sm"
          weight="font-medium"
          isOptional={!disableField && showOptionalLabel}
        >
          Describe why {candidateFirstName} is a match
        </Label>
        <div className="flex gap-4 items-end">
          <div className="h-full w-full">
            {checkIsEmpty(briefingValue) && disableField ? (
              <div className="border rounded-lg border-neutral-light bg-neutral-lightest px-4 h-10 flex items-center">
                <Paragraph className="text-neutral-medium" size="body-md">
                  E.g.: {candidateFirstName} stands out as a top candidate because...
                </Paragraph>
              </div>
            ) : (
              !isLoading && (
                <RichTextEditor
                  name="briefing"
                  setValue={setValue}
                  readOnly={disableField}
                  showBoldOption={isStriderStaff}
                  showHighlightOption={isStriderStaff}
                  defaultValue={briefingValue}
                  className={cn({
                    'border rounded-lg border-neutral-light bg-neutral-lightest px-4': disableField,
                  })}
                  omitErrors
                />
              )
            )}
          </div>
          {disableField && (
            <SecondaryButton
              size="md"
              icon={<EditIcon className="h-[16px] w-[16px] stroke-neutral-darkest" />}
              onClick={handleEditBriefing}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <Label htmlFor="highlightedSkills" size="text-sm" weight="font-medium">
          Select {candidateFirstName}'s skills to highlight
        </Label>
        <SelectTags
          id="highlightedSkills"
          name="highlightedSkills"
          setValue={setValue}
          watch={watch}
          options={stacks}
          maxTagsNumber={8}
          allowCreation
          onCreateOption={handleCreateStack}
          selectedOptions={selectedSkills}
        >
          <Paragraph size="body-sm" className="text-neutral-dark">
            Select up to 8
          </Paragraph>
        </SelectTags>
      </div>
    </div>
  )
}
