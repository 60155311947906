import { cn } from 'modules/shared'
import { FieldError } from 'shared/components/atoms'
import { FieldSuccess } from 'shared/components/atoms'
import {
  forwardRef,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  MutableRefObject,
  ReactNode,
} from 'react'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (key: string) => Array<any>
  name: string
  type?: HTMLInputTypeAttribute
  disabled?: boolean
  className?: string
  showFieldError?: boolean
  placeholder?: string
  injected?: boolean
  children?: ReactNode
  warningOnly?: boolean
  enableErrorState?: boolean
  customError?: string
  successMessage?: string
  showBorder?: boolean
  errorStyles?: 'danger' | 'warning'
}

export const inputStyles = ({
  showError,
  error,
  className,
  injected,
  showBorder = true,
  errorStyles,
}: {
  showError: boolean
  error?: boolean
  className?: string
  injected?: boolean
  showBorder?: boolean
  errorStyles?: 'danger' | 'warning'
}) => {
  if (injected) {
    return cn(
      'h-auto outline-transparent',
      'font-inter font-normal text-base text-neutral-darkest',
      className,
    )
  }

  return cn(
    'w-full h-10 px-4 py-2',
    'font-inter font-normal text-base text-neutral-darkest',
    'disabled:bg-neutral-lightest disabled:border-neutral-light disabled:text-neutral-medium disabled:shadow-none',
    {
      'outline outline-2 outline-offset-[-2px]': error && showError,
      'bg-danger-lighter outline-danger-light': error && showError && errorStyles === 'danger',
      'bg-warning-lighter outline-warning-medium': error && showError && errorStyles === 'warning',
      'border-[1.5px] border-neutral-light rounded': showBorder,
    },
    className,
  )
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    id,
    register,
    name,
    disabled,
    type,
    className,
    showFieldError = true,
    placeholder,
    injected = false,
    children,
    warningOnly = false,
    enableErrorState = false,
    customError,
    successMessage,
    showBorder = true,
    errorStyles = 'danger',
    onPaste,
    autoComplete,
    onBlur,
    onChange,
    value,
    ...restProps
  }: InputProps,
  ref,
) {
  const [error, fieldRegister] = register(name)

  const classes = inputStyles({
    showError: showFieldError || enableErrorState,
    error: Boolean(customError || error),
    className,
    injected,
    showBorder,
    errorStyles,
  })
  const { ref: fieldRef, ...restFieldProps } = fieldRegister({
    autoComplete,
    onBlur,
    onChange,
    value,
  })

  return (
    <>
      <input
        id={id || name}
        className={classes}
        {...restProps}
        {...restFieldProps}
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        ref={(element) => {
          fieldRef(element)

          if (typeof ref === 'function') {
            ref(element)
          } else if (ref) {
            const mutableRef = ref as MutableRefObject<HTMLInputElement | null>
            mutableRef.current = element
          }
        }}
        onPaste={onPaste}
      />
      {children}
      {showFieldError && !successMessage && (
        <FieldError message={warningOnly ? undefined : error || customError} />
      )}
      {Boolean(successMessage) && <FieldSuccess message={successMessage} />}
    </>
  )
})
