import { lazy, useContext, useEffect, useRef } from 'react'

import { AnchorBar, ClaimProtected, Spinner, Tab } from 'shared/components/atoms'
import { EmployerActions } from 'components/organisms/EmployerActions'
import { AssessmentTagsSection } from 'profile/exports'
import { InlineEmptySection } from 'pages/JobSeekerProfile/components/EmptySection'
import { ProfileOverviewSection } from 'pages/JobSeekerProfile/components/ProfileOverviewSection'
import { AccountClaim, useAuth, useBrowserPrint } from 'shared/hooks'
import { CandidateContext } from 'contexts/candidate'
import { SearchContext } from 'candidates-search/contexts'
import { Candidate as CandidateDomain } from 'candidates-search/domain'
import { ProfileSection } from 'candidates-search/enums'

import { CandidateInfo } from '..'
import { TechnicalAssessmentSection } from './TechnicalAssessmentSection'
import { ScreeningSection } from './ScreeningSection'
import { Content } from './content'

const Timeline = lazy(() => import('..').then((module) => ({ default: module.Timeline })))

interface CandidateProps {
  searchCandidate: CandidateDomain
  isLoading: boolean
  initialSection: ProfileSection
  setDefaultSection: (section: ProfileSection) => void
}

export const Candidate = ({
  searchCandidate,
  isLoading,
  initialSection,
  setDefaultSection,
}: CandidateProps) => {
  const overviewRef = useRef<HTMLDivElement>(null)
  const experienceRef = useRef<HTMLDivElement>(null)
  const staRef = useRef<HTMLDivElement>(null)
  const screeningRef = useRef<HTMLDivElement>(null)
  const timelineRef = useRef<HTMLDivElement>(null)

  const { search } = useContext(SearchContext)
  const {
    user: { claims },
  } = useAuth()

  const { printableKey, printableRef, handlePrint } = useBrowserPrint()

  const {
    candidate,
    isLoading: isProfileLoading,
    isReloading,
    loadCandidate,
  } = useContext(CandidateContext)

  const { workExperience, educations, certifications } = new Content(searchCandidate, candidate)

  const tabs = [
    { label: 'Summary', ref: overviewRef, visible: true },
    { label: 'Experience & education', ref: experienceRef, visible: true },
    { label: 'Technical assessment', ref: staRef, visible: candidate.assessments.length > 0 },
    {
      label: 'Screening',
      ref: screeningRef,
      visible: Boolean(
        candidate.jobOpportunities?.find(
          (jobOpportunity) => jobOpportunity.listingId == search?.listing?.id,
        ),
      ),
    },
    {
      label: 'Timeline',
      ref: timelineRef,
      visible: claims.includes(AccountClaim.TimelineView),
    },
  ]

  useEffect(() => {
    loadCandidate(searchCandidate.id)
  }, [searchCandidate.id])

  useEffect(() => {
    if (candidate.id) {
      const sectionRef = {
        [ProfileSection.Overview]: printableRef,
        [ProfileSection.WorkExperience]: experienceRef,
        [ProfileSection.Assessment]: staRef,
        [ProfileSection.Screening]: screeningRef,
      }[initialSection]

      sectionRef?.current?.scrollIntoView({ behavior: 'smooth' })
      setDefaultSection(ProfileSection.Overview)
    }
  }, [candidate.id])

  if ((isProfileLoading && !isReloading) || isLoading) {
    return (
      <div className="w-screen md:w-[1000px] h-full">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="w-screen max-w-[1000px] relative" key={printableKey}>
      <div className="relative" ref={printableRef}>
        <div className="p-10">
          <CandidateInfo candidate={searchCandidate} fromDrawer>
            <div className="ml-auto">
              <EmployerActions
                profile={candidate}
                onExport={handlePrint}
                withExtendedOptions
                isStandAloneProfile={false}
              />
            </div>
          </CandidateInfo>
        </div>
        <AnchorBar
          tabs={tabs.filter((tab) => tab.visible) as Tab[]}
          className="sticky top-0 mb-10 print:hidden"
        />
        <div className="px-10">
          <AssessmentTagsSection
            profileId={candidate.id}
            tags={candidate.assessmentTags}
            lastUpdatedAt={candidate.tagsUpdatedAt}
            containerClassName="mb-16"
          />

          <section ref={overviewRef}>
            <ProfileOverviewSection
              readOnly
              profile={candidate}
              onBioEdit={() => {}}
              onEdit={() => {}}
              bioPlaceholder={<InlineEmptySection title="Bio" message="There is no bio to show" />}
            />
          </section>
          <section ref={experienceRef}>
            {workExperience}
            {educations}
            {certifications}
          </section>
          <TechnicalAssessmentSection ref={staRef} profile={candidate} />
          <ScreeningSection
            ref={screeningRef}
            profile={candidate}
            openAnswers={initialSection === ProfileSection.Screening}
            setDefaultSection={setDefaultSection}
          />
          <ClaimProtected require={AccountClaim.TimelineView}>
            <div ref={timelineRef} className="min-h-[600px]">
              <Timeline
                profileId={candidate.id}
                matchingId={search?.getMatchingByProfileId(candidate.id)?.matchingId}
                showMissingMatchingBanner={search?.isListingLinked}
              />
            </div>
          </ClaimProtected>
        </div>
      </div>
    </div>
  )
}
