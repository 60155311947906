import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'

import { RouterContext } from 'contexts/router'

import { useLandingTracking, useNetwork } from 'shared/hooks'

const onboardingKey = 'onb'

export const useOnboarding = () => {
  const route = useContext(RouterContext)

  const client = useQueryClient()
  const { post } = useNetwork()
  const navigate = useNavigate()
  const { getLandingLocation } = useLandingTracking()
  const [seamlessOnboarded, setSeamlessOnboarded] = useState(false)

  const { mutateAsync: doSeamlessOnboarding } = useMutation(async () => post('/onboarding'), {
    onSuccess: async () => {
      setSeamlessOnboarded(true)
      await client.invalidateQueries('pending-agreements')
      await client.invalidateQueries('accounts/me')
      navigate(route.from ?? getLandingLocation())
    },
  })

  const getPartialData = () => {
    const data = localStorage.getItem(onboardingKey)
    if (data) return JSON.parse(data)
    return null
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const persistPartialData = (values: { [x: string]: any }) =>
    localStorage.setItem(onboardingKey, JSON.stringify(values))

  const clearPersistedData = () => localStorage.removeItem(onboardingKey)

  return {
    doSeamlessOnboarding,
    seamlessOnboarded,
    getPartialData,
    persistPartialData,
    clearPersistedData,
  }
}
