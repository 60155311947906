import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as SettingsIcon } from 'icons/listings/settings.svg'

import { AccountClaim, useAuth } from 'shared/hooks'

import { ListingInfo } from 'job-listing/domains'

import { ListingSettingsModal } from './components'
import { CollapsedMenu, CollapsedMenuItem } from 'shared/components/molecules'
import { ButtonType } from 'shared/components/atoms'

interface ListingManagementMenuProps {
  listing: ListingInfo
  readOnly?: boolean
}

export const ListingManagementMenu = ({ listing, readOnly }: ListingManagementMenuProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const {
    user: { claims },
  } = useAuth()
  const navigate = useNavigate()

  const handleOpenListingSettings = () => setIsModalOpen(true)
  const handleCloseListingSettings = () => setIsModalOpen(false)

  const handleOpenScreenings = () => navigate(`/listings/${listing.id}/screenings`)

  const menuItems = useMemo<Array<CollapsedMenuItem>>(
    () =>
      [
        {
          label: 'Listing settings',
          callback: handleOpenListingSettings,
          visible: claims.includes(AccountClaim.ManageJobListingSettings),
        },
        {
          label: 'Screenings',
          callback: handleOpenScreenings,
          visible: claims.includes(AccountClaim.ScreeningManagement),
        },
      ].filter(({ visible }) => visible),
    [],
  )

  if (readOnly || !menuItems.length) return null

  return (
    <>
      <CollapsedMenu
        buttonType={ButtonType.Tertiary}
        triggerElement={<SettingsIcon className="stroke-neutral-darker" />}
        items={menuItems}
        triggerElementSize="lg"
        aria-label="Listing settings"
      />
      <ListingSettingsModal
        isOpen={isModalOpen}
        listing={listing}
        onClose={handleCloseListingSettings}
      />
    </>
  )
}
