import classNames from 'classnames'
import { Paragraph } from 'shared/components/atoms'
import { Text } from 'components/Text'

interface Props {
  path: string
  title: string
  description: string
  className?: string
}

const Description = ({ path, title, description, className }: Props) => {
  return (
    <div className={classNames('flex flex-col gap-6 items-center', className)}>
      <img src={path} width="460" height="350" className="max-w-[480px] w-full h-full" />
      <div className="flex flex-col gap-3 max-w-[480px] text-center">
        <Text size="large-body" weight="font-medium">
          {title}
        </Text>
        <Paragraph size="body-sm" weight="font-normal">
          {description}
        </Paragraph>
      </div>
    </div>
  )
}

export { Description }
