import { AssessmentStatus, AssessmentResult, AssessmentDomain } from '../enums'

export class Assessment {
  status: AssessmentStatus
  result: AssessmentResult
  url: Nullable<string>
  domain: AssessmentDomain

  get passed() {
    return [AssessmentResult.Pass, AssessmentResult.StrongPass].includes(this.result)
  }

  get failed() {
    return [AssessmentResult.Fail, AssessmentResult.StrongFail].includes(this.result)
  }

  get inProgress() {
    return this.status === AssessmentStatus.InProgress
  }

  get isReportAvailable() {
    return Boolean(this.url)
  }

  get progressLabel() {
    if (this.inProgress) return 'Vetting in progress'
    if (this.passed) return 'Vetted'
    if (this.failed) return 'Failed vetting'
  }
}
