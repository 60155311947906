import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { useMonitoring, useNetwork, useToast } from 'shared/hooks'
import { getFormListing } from 'job-listing/exports'

import { SubscribersRequest } from 'job-listing/dtos'
import { FormListing } from 'job-listing/domains'
import { ListingStatus } from 'job-listing/enums'
import { CreateListingDTO, HomepageListing, ListingResponse } from 'job-listing/dtos'

export const useListingsFetch = (page: number) => {
  const [allListings, setAllListings] = useState<Array<HomepageListing>>([])

  const { get } = useNetwork()

  const {
    data = { listings: [], count: 0 },
    isLoading,
    isFetching,
    isRefetching,
  } = useQuery(`listings?page=${page}`, () =>
    get<{ listings: Array<HomepageListing>; count: number }>(`listings?page=${page}`).then(
      ({ data }) => data,
    ),
  )

  useEffect(() => {
    if (isLoading || isRefetching || isFetching) return
    if (!data?.listings.length) return

    if (page === 1) return setAllListings(data.listings)

    const updatedListings = allListings.concat(data.listings)

    setAllListings(updatedListings)
  }, [isLoading, isRefetching, data])

  return {
    listings: allListings,
    count: data.count,
    isLoading: isLoading || isFetching || isRefetching,
  }
}

// @todo - move this code to the global useJobListing hook and refactor to apply useMutation
export const useListing = () => {
  const client = useQueryClient()

  const { post, patch, get } = useNetwork()
  const { toastError } = useToast()

  const { captureException } = useMonitoring()

  const create = async (listing: FormListing) => {
    const payload = new CreateListingDTO(listing).toJSON()
    return post<{ id: string }>('listings', payload).then(async ({ data: { id } }) => {
      await subscribeToNotifications(id, listing)
      return id
    })
  }

  const update = async (listing: FormListing) => {
    const payload = new CreateListingDTO(listing).toJSON()

    await patch(`listings/${listing.id}`, payload).then(async () => {
      await subscribeToNotifications(listing.id!, listing)
    })

    return listing.id
  }

  const { mutateAsync: createListing, isLoading: isSaving } = useMutation(
    (listing: FormListing) => {
      if (listing.id) {
        return update(listing)
      }

      return create(listing)
    },
    {
      onError: (error: RequestError) => {
        toastError('Failed to save listing: ' + error.response?.data.message)
        captureException(error)
      },
    },
  )

  const { mutateAsync: updateListingStatus } = useMutation(
    ({ id, status }: { id: string; status: ListingStatus }) =>
      patch(`listings/${id}/status/${status}`),
    {
      onSuccess: () => client.invalidateQueries('listings'),
      onError: (error: RequestError) => {
        toastError('Failed to update listing status: ' + error.response?.data.message)
        captureException(error)
      },
    },
  )

  const { mutateAsync: publishListing, isLoading: isPublishing } = useMutation(
    ({ id, termId }: { id: string; termId?: number }) =>
      post(`/listings/${id}/publish`, { termId: termId ?? null }),
    {
      onError: (error: RequestError) => {
        toastError('Failed to publish listing: ' + error.response?.data.message)
        captureException(error)
      },
    },
  )

  const subscribeToNotifications = async (id: string, listing: FormListing) => {
    return await post(
      `/listings/${id}/notifications/subscribe`,
      new SubscribersRequest(listing).toJSON(),
    ).catch((error: RequestError) => {
      toastError('Failed to subscribe to notifications: ' + error.response?.data.message)
      captureException(error)
    })
  }

  const getListingPreview = async (id: string) =>
    await get<ListingResponse>(`listings/${id}/preview`).then(({ data }) => getFormListing(data))

  return {
    updateListingStatus,
    getListingPreview,
    createListing,
    publishListing,
    isSaving,
    isPublishing,
  }
}
