import { Text, TextDiffViewer, Tooltip } from 'shared/components/atoms'
import { ChangeLog } from 'candidates-search/domain'
import { ReactComponent as ArrowIcon } from 'icons/right-arrow.svg'
import { ChangeLogAuthor, ListingData, ScreeningVersion } from './components'

interface ItemDetailsProps {
  isOpen: boolean
  changeLog: ChangeLog
}

export const ItemDetails = ({ isOpen, changeLog }: ItemDetailsProps) => {
  if (!isOpen) return null

  const showHeader = Boolean(changeLog.author || changeLog.screening || changeLog.isMatchingRelated)
  const showDetails = Boolean(changeLog.details?.length)

  return (
    <>
      {showHeader && (
        <div className="-ml-10 pl-10 pb-8 pt-2">
          <div className="flex gap-8">
            <ListingData changeLog={changeLog} />
            <div className="flex flex-col gap-4">
              <ChangeLogAuthor changeLog={changeLog} />
              <ScreeningVersion changeLog={changeLog} />
            </div>
          </div>
        </div>
      )}
      {showDetails && (
        <div className="-ml-10 pl-10 pb-8 pt-2">
          <ul className="flex flex-col gap-6 p-4 bg-neutral-lightest rounded-lg">
            {changeLog.details!.map((detail) => (
              <li className="flex flex-row gap-2" key={detail.date}>
                {Boolean(detail.propertyDescription) && (
                  <Tooltip
                    content={detail.propertyDescription}
                    childrenClassName="flex items-center max-w-md"
                  >
                    <Text
                      size="text-xs"
                      weight="font-normal"
                      className="text-neutral-dark max-w-md whitespace-nowrap overflow-hidden text-ellipsis"
                    >
                      {detail.propertyDescription}
                    </Text>
                  </Tooltip>
                )}
                {detail.isLongText ? (
                  <TextDiffViewer
                    oldValue={detail.formattedOldValue?.toString() || ''}
                    newValue={detail.formattedNewValue?.toString() || ''}
                  />
                ) : (
                  <>
                    {detail.hasOldValue && (
                      <Text
                        size="text-xs"
                        weight="font-normal"
                        className="text-neutral-dark line-through"
                      >
                        {detail.formattedOldValue}
                      </Text>
                    )}
                    {detail.hasOldValue && detail.hasNewValue && (
                      <ArrowIcon className="w-3 h-3 stroke-neutral-darkest flex-shrink-0 my-auto" />
                    )}
                    {detail.hasNewValue && (
                      <Text size="text-xs" weight="font-normal" className="text-neutral-darkest">
                        {detail.formattedNewValue}
                      </Text>
                    )}
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  )
}
