import { AlertDialog } from 'shared/components/molecules'
import {
  EmployerOwnerOnly,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from 'shared/components/atoms'
import { JobListingPauser } from 'job-listing/components'
import { Text } from 'components/Text'
import { ReactComponent as PauseIcon } from 'icons/pause.svg'

interface FormActionsProps {
  isLoading: boolean
  savable: boolean
  isDirty: boolean
  listingId?: string
  draftable: boolean
  pausable: boolean
  isOnboarding?: boolean
  onCancel: () => void
  primaryActionLabel: string
  onDraft: () => void
  onPause: () => void
  onLeavingSave: () => void
  isTalentReview: boolean
}

export const FormActions = ({
  onCancel,
  primaryActionLabel,
  onDraft,
  draftable,
  pausable,
  savable,
  isOnboarding,
  listingId,
  onPause,
  isDirty,
  onLeavingSave,
  isLoading,
  isTalentReview,
}: FormActionsProps) => {
  const handleDraft = () => onDraft()

  const actions = (
    <>
      {draftable && (
        <SecondaryButton onClick={handleDraft} isLoading={isLoading}>
          Save as draft
        </SecondaryButton>
      )}
      <SecondaryButton onClick={onCancel}>Leave without saving</SecondaryButton>
      {savable && (
        <PrimaryButton onClick={onLeavingSave} isLoading={isLoading}>
          Save and leave
        </PrimaryButton>
      )}
    </>
  )

  const description = (
    <div className="flex flex-col">
      <Text className="text-base">
        Leaving this page without saving will lose any changes you made to this form.
      </Text>
      <br />
      <Text className="text-warning-dark text-base">Do you want to leave anyway?</Text>
    </div>
  )

  const handleCancel = () => {
    if (isDirty) return

    onCancel()
  }

  const defaultActions = (
    <>
      {draftable && (
        <SecondaryButton size="md" onClick={handleDraft} isLoading={isLoading}>
          Save draft
        </SecondaryButton>
      )}
      <PrimaryButton size="md" type="submit" isLoading={isLoading}>
        {primaryActionLabel}
      </PrimaryButton>
    </>
  )

  const Actions = (
    <div className="flex flex-col-reverse sm:flex-row gap-4 mt-7">
      {pausable && !isOnboarding && (
        <JobListingPauser
          listingId={String(listingId)}
          onPause={onPause}
          isTalentReview={isTalentReview}
        >
          {({ onClick }) => (
            <TertiaryButton icon={<PauseIcon />} isIconBefore onClick={onClick}>
              Pause listing
            </TertiaryButton>
          )}
        </JobListingPauser>
      )}
      <AlertDialog
        title="You have unsaved changes"
        description={description}
        cancelText="Nevermind"
        customActions={actions}
        trigger={
          <TertiaryButton className="sm:ml-auto" onClick={handleCancel}>
            Cancel
          </TertiaryButton>
        }
        onConfirmClick={onCancel}
      />
      {defaultActions}
    </div>
  )

  return <EmployerOwnerOnly>{Actions}</EmployerOwnerOnly>
}
