import { useNetwork } from 'shared/hooks'
import { useToast } from 'shared/hooks'
import { useMutation, useQueryClient } from 'react-query'
import { ProfileEducation } from '../types'
import { EducationFormValuesFactory } from 'modules/profiles/factories'
import { EducationFormValues } from 'modules/profiles/domain'
import { UpsertEducationDTO } from 'modules/profiles/dtos'
import { useInstitution } from './useInstitution'
import { useAuth } from 'shared/hooks'
import { useMonitoring } from 'shared/hooks'

export const useEducation = (profileId: string) => {
  const client = useQueryClient()
  const { post, put, delete: deleteReq } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()
  const {
    user: { isJobSeekerProfilesManager },
  } = useAuth()

  const { institutions, publishInstitution } = useInstitution(profileId)

  const { mutateAsync: deleteEducation, isLoading: isDeleting } = useMutation(
    (educationId: string) => deleteReq(`profile/${profileId}/education/${educationId}`),
    {
      onSuccess: () => {
        client.invalidateQueries('profile')
        client.invalidateQueries(`candidates/${profileId}`)
      },
      onError: (error: RequestError) => {
        toastError('Error when deleting the education: ' + error.response?.data.message)
        captureException(error)
      },
    },
  )

  const { mutateAsync: upsertEducation, isLoading: isUpserting } = useMutation(
    async (education: EducationFormValues) => {
      const institution = institutions.find(({ id }) => education.institutionId === id)
      if (institution && Boolean(institution.authorId) && isJobSeekerProfilesManager)
        await publishInstitution(institution.id)

      if (education.id) return update(education)
      return create(education)
    },
    {
      onSuccess: () => {
        client.invalidateQueries('profile')
        client.invalidateQueries(`candidates/${profileId}`)
      },
      onError: (error: RequestError) => {
        toastError('Error when upserting the education record: ' + error.response?.data.message)
        captureException(error)
      },
    },
  )

  const create = async (education: EducationFormValues) => {
    return post(`profile/${profileId}/education`, new UpsertEducationDTO(education).toJSON())
  }

  const update = async (education: EducationFormValues) => {
    return put(
      `profile/${profileId}/education/${education.id}`,
      new UpsertEducationDTO(education).toJSON(),
    )
  }

  const parseEducationToFormSchema = (education: ProfileEducation) => {
    return EducationFormValuesFactory.build(education)
  }

  return { upsertEducation, deleteEducation, parseEducationToFormSchema, isUpserting, isDeleting }
}
