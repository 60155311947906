import { Tooltip } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'

import { ReactComponent as ChartIcon } from 'icons/listings/bar-chart-square-02.svg'

interface ExperienceLabelProps {
  label: string
  color: 'neutral-dark' | 'neutral-darkest'
}

export const ExperienceLabel = ({ label, color }: ExperienceLabelProps) => {
  if (!label) return null

  return (
    <Tooltip content="Experience">
      <div className="flex items-center gap-2 shrink-0">
        <ChartIcon className={`w-4 h-4 stroke-${color}`} />
        <Text size="text-sm" weight="font-normal" className={`text-${color}`}>
          {label}
        </Text>
      </div>
    </Tooltip>
  )
}
