import { useEffect, useState } from 'react'

const REDIRECT_TO_REFERRALS = 'redirect_to_referrals'

// @todo: refactor to work in a generic way - STR-3335
export const useRedirectToReferrals = () => {
  const initialState = JSON.parse(localStorage.getItem(REDIRECT_TO_REFERRALS) || 'false')
  const [shouldRedirectToReferrals, setShouldRedirect] = useState(initialState)

  const setShouldRedirectToReferrals = () => setShouldRedirect(true)
  const removeShouldRedirectToReferrals = () => setShouldRedirect(false)

  const onSessionStart = (landingLocation?: string) => {
    if (landingLocation?.includes('/referrals')) setShouldRedirectToReferrals()
  }

  useEffect(() => {
    if (shouldRedirectToReferrals) {
      localStorage.setItem(REDIRECT_TO_REFERRALS, 'true')
      return
    }

    localStorage.removeItem(REDIRECT_TO_REFERRALS)
  }, [shouldRedirectToReferrals])

  return {
    shouldRedirectToReferrals,
    setShouldRedirectToReferrals,
    removeShouldRedirectToReferrals,
    onSessionStart,
  }
}
