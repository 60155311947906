import { MouseEvent, useMemo, useState } from 'react'

import { SecondaryButton, Text, Title } from 'shared/components/atoms'
import { AlertDialog, InfoBanner } from 'shared/components/molecules'
import { SyncedToCloseBadge } from 'components/molecules'

import { AccountClaim, useAuth, useMonitoring, useToast } from 'shared/hooks'
import { useListing } from 'job-listing/hooks'
import { useMatchingsReports } from 'hooks/matchings'

import { ListingStatus } from 'job-listing/enums'

interface JobListingPauserDialogProps {
  listingId: string
  isOpen: boolean
  isTalentReview: boolean
  closeDialog: () => void
  onPause: () => void
}

export const JobListingPauserDialog = ({
  listingId,
  isOpen,
  isTalentReview,
  closeDialog,
  onPause,
}: JobListingPauserDialogProps) => {
  const { updateListingStatus } = useListing()
  const { toastSuccess, toastError } = useToast()
  const { captureException } = useMonitoring()

  const {
    report: { count },
  } = useMatchingsReports(listingId)

  const customInfo = useMemo(() => {
    if (isTalentReview) {
      return (
        <Text>
          No candidate will be notified about the pausing of this job listing because it's set to
          <span className="italic">Talent Review</span>.
        </Text>
      )
    }

    if (count === 0) {
      return <Text>No candidate is at the stage of being notified about this pausing.</Text>
    }

    return (
      <Text>
        {count} candidate(s) will be notified by email about the pausing of this job listing after a
        few minutes delay.
      </Text>
    )
  }, [count])

  const pauseListing = () =>
    updateListingStatus({ id: listingId, status: ListingStatus.Paused })
      .then(() => {
        toastSuccess('Job listing paused.')
        closeDialog()
        onPause()
      })
      .catch((error) => {
        toastError('Could not pause the job listing. Please try again.')
        closeDialog()
        captureException(error)
      })

  return (
    <AlertDialog
      isOpen={isOpen}
      title={
        <div className="flex gap-4 items-center">
          <Title size="heading">Pause job listing</Title>
          <SyncedToCloseBadge />
        </div>
      }
      description={
        <div className="flex flex-col gap-6">
          <Text size="text-base">
            When a job listing is paused, it's no longer possible to add new candidates to the
            process, and the active screening will be archived. To resume using this job listing, it
            will need to be republished.
          </Text>
          <InfoBanner bgColor="bg-info-lighter" iconColor="stroke-info-medium">
            {customInfo}
          </InfoBanner>
        </div>
      }
      customActions={<SecondaryButton onClick={pauseListing}>Pause job listing</SecondaryButton>}
      onCancelClick={closeDialog}
    />
  )
}

interface JobListingPauserProps {
  onPause: () => void
  children: ({ onClick }: { onClick: (event: MouseEvent<HTMLElement>) => void }) => JSX.Element
  listingId: string
  isTalentReview: boolean
}

export const JobListingPauser = ({
  isTalentReview,
  children,
  listingId,
  onPause,
}: JobListingPauserProps) => {
  const {
    user: { claims },
  } = useAuth()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const openDialog = () => setIsOpen(true)
  const closeDialog = () => setIsOpen(false)

  const handleListingPause = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    openDialog()
  }

  if (!claims.includes(AccountClaim.PauseJobListings)) return null

  return (
    <>
      {children({ onClick: handleListingPause })}
      {isOpen && (
        <JobListingPauserDialog
          listingId={listingId}
          isOpen={isOpen}
          closeDialog={closeDialog}
          onPause={onPause}
          isTalentReview={isTalentReview}
        />
      )}
    </>
  )
}
