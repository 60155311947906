export enum AssessmentStatus {
  Disregard = 'disregard',
  InProgress = 'in_progress',
  InReview = 'in_review',
  Done = 'done',
}

export enum AssessmentResult {
  StrongPass = 'strong_pass',
  Pass = 'pass',
  Fail = 'fail',
  StrongFail = 'strong_fail',
}

export enum AssessmentDomain {
  FullStack = 'Full-stack Web',
  Backend = 'Back-end Web',
  Frontend = 'Front-end Web',
  Mobile = 'Mobile',
  DataEngineering = 'Data Engineering',
  DataScience = 'Data Science',
  WebScraping = 'Web Scraping',
  SDRAccountExecutive = 'SDR / Account Executive',
  RecruiterLeadRecruiter = 'Recruiter / Lead Recruiter',
  UnknownReport = 'Evaluation report',
}
