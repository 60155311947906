import { Paragraph } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'
import { ContentWrapper } from 'components/ContentWrapper'
import { Label } from 'shared/components/atoms'
import { Link } from 'shared/components/atoms'
import { Toggle } from 'shared/components/atoms'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'

interface AllowAdvertisementProps {
  watch: UseFormWatch<Record<string, boolean>>
  setValue: UseFormSetValue<Record<string, string>>
}

export const AllowAdvertisement = ({ watch, setValue }: AllowAdvertisementProps) => {
  return (
    <ContentWrapper className="mt-3 flex gap-4 items-start !flex-nowrap !mb-0">
      <Toggle
        name="allowsAdvertiseProfile"
        setValue={setValue}
        checked={watch('allowsAdvertiseProfile')}
      />
      <Label htmlFor="allowsAdvertiseProfile" className="flex flex-col gap-1">
        <Text size="text-sm" weight="font-medium" className="text-neutral-darkest">
          Allow Strider to use my profile for advertisement
        </Text>
        <Paragraph size="body-sm" weight="font-normal" className="text-neutral-dark mt-2">
          Allowing Strider to advertise your profile means giving permission for Strider to use your
          profile data, such as your photo, role, and skills, to promote you to potential clients or
          employers on <Link url="https://onstrider.com">www.onstrider.com</Link>
          <Paragraph size="body-sm" weight="font-normal" className="text-neutral-dark mt-4">
            <ul className="text-inherit list-disc px-6">
              <li className="text-inherit">Increase your visibility to potential employers</li>
              <li className="text-inherit">Receive more job offers</li>
              <li className="text-inherit">
                You may have an advantage over other candidates who have not allowed their profiles
                to be advertised
              </li>
            </ul>
          </Paragraph>
        </Paragraph>
      </Label>
    </ContentWrapper>
  )
}
