import { ReactNode, forwardRef, lazy } from 'react'

import { FieldError } from 'shared/components/atoms'

import { inputStyles } from '../Input'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'

const ReactPhoneInput = lazy(
  () => import(/*  webpackPreload: true */ 'react-phone-number-input/input'),
)

interface PhoneInputProps {
  name: string
  id?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (key: string) => Array<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<Record<string, any>>
  className?: string
  placeholder?: string
  showFieldError?: boolean
  children?: ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<Record<string, any>>
}

export const isValidPhoneNumber = async (phone: string) => {
  const { isPossiblePhoneNumber } = await import('react-phone-number-input/input')

  return isPossiblePhoneNumber(phone)
}

export const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(function PhoneInput(
  {
    name,
    register,
    className,
    placeholder,
    children,
    watch,
    setValue,
    showFieldError = true,
    ...restProps
  }: PhoneInputProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any,
) {
  const [error, fieldRegister] = register(name)

  const classes = inputStyles({
    showError: showFieldError,
    error,
    className,
  })
  const { ref: fieldRef, onChange, ...restFieldProps } = fieldRegister()
  const value = watch(name)

  const handleChange = (value: string) => setValue(name, value)

  return (
    <>
      <ReactPhoneInput
        value={value}
        className={classes}
        {...restFieldProps}
        {...restProps}
        onChange={handleChange}
        placeholder={placeholder}
        ref={(element) => {
          fieldRef(element)
          if (ref) {
            ref.current = element
          }
        }}
      />
      {children}
      {showFieldError && <FieldError message={error} />}
    </>
  )
})
