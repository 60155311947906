import { Theme } from '@clerk/types'

type FormElements = Theme['elements']

export const appearance: Theme = {
  elements: {
    rootBox: 'md:w-[540px] w-full font-inter',
    main: 'gap-4',
    card: 'w-full shadow-none',
    headerSubtitle: 'hidden',
    headerTitle: 'font-medium text-base md:text-xl text-neutral-darkest font-medium',
    dividerLine: 'hidden',
    dividerText: 'uppercase text-neutral-medium tracking-tight font-medium text-sm',
    socialButtonsBlockButton: 'flex items-center w-full h-12',
    socialButtonsBlockButtonArrow: 'hidden',
    socialButtonsBlockButtonText:
      'flex-grow-0 flex flex-row items-center gap-x-3 p-0 m-0 font-normal text-base text-left text-neutral-darkest',
    formButtonPrimary: `h-12 bg-neutral-darkest hover:bg-neutral-darkest text-base font-normal normal-case`,
    formFieldInput: 'h-12 text-base',
    footerAction: 'flex w-full justify-center',
    formFieldLabel: 'font-medium text-sm text-neutral-darkest mb-1',
    footerActionText: 'font-medium text-sm text-neutral-darkest',
    footerActionLink: 'font-medium text-sm text-info-medium',
    headerBackLink: 'text-neutral-darkest',
  },
}

export const signUpAppearance: (centralized: boolean) => Theme = (centralized: boolean) => ({
  elements: Object.assign<object, FormElements, FormElements>({}, appearance.elements, {
    logoBox: centralized ? 'flex justify-center -mt-10' : 'flex',
    logoImage: centralized ? '-mt-32' : undefined,
    headerTitle: 'font-medium text-base md:text-xl text-neutral-darkest font-medium',
    formFieldRow__name: 'hidden',
  }),
})
