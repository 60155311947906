import { SignIn as ClerkSignInWidget } from '@clerk/clerk-react'

import { appearance } from 'authentication/consts'

interface SignInProps {
  redirectUrl: string | null
}

export const SignInForm = ({ redirectUrl }: SignInProps) => {
  return <ClerkSignInWidget signUpUrl="/signup" redirectUrl={redirectUrl} appearance={appearance} />
}
