import { Autocomplete, Suggestion } from 'shared/components/atoms'
import { FieldError } from 'shared/components/atoms'
import { Label } from 'shared/components/atoms'
import { useInstitution } from 'pages/JobSeekerProfile/hooks/useInstitution'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'

interface InstitutionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register(key: string): any[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<Record<string, any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<Record<string, any>>
  getError(field: string): string
  title: string
  profileId: string
}

export const Institution = ({
  register,
  setValue,
  watch,
  getError,
  title,
  profileId,
}: InstitutionProps) => {
  const { createInstitution, institutions } = useInstitution(profileId)

  const setInstitution = (data: Suggestion) => {
    setValue('institutionId', data.id, { shouldValidate: true })
    setValue('institutionName', data.label, { shouldValidate: true })
  }

  const handleInstitutionChange = async (value: Suggestion) => {
    if (value.id) return setInstitution(value)
    createInstitution(value.label).then((response) => {
      setInstitution(response.data)
    })
  }

  return (
    <>
      <Label htmlFor="institutionName">{title}</Label>
      <Autocomplete
        allowCreation
        showHint={false}
        clearInputValue={false}
        register={register}
        watch={watch}
        name="institutionName"
        suggestions={institutions}
        handleAdd={handleInstitutionChange}
        placeholder="College, University, Institution ..."
        distance={600}
      />
      <FieldError message={getError('institutionName')} />
    </>
  )
}
