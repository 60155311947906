import { Paragraph } from 'shared/components/atoms'
import { InfoBanner } from 'shared/components/molecules'
import { Label } from 'shared/components/atoms'
import { Modal } from 'shared/components/molecules'
import { StyledSelect, SelectOption } from 'shared/components/atoms'

import { useForm } from 'shared/hooks'

import { schema } from '.'
import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { PrimaryButton, TertiaryButton } from 'shared/components/atoms'
import { ModalHeader } from '../ModalHeader'

interface SelectMatchingModalProps {
  profile: ProfileOverview
  onConfirm: (matchingId: string) => void
  onCancel: () => void
}

export const SelectMatchingModal = ({ profile, onCancel, onConfirm }: SelectMatchingModalProps) => {
  const { register, setValue, watch, handleSubmit, getValues } = useForm({
    schema,
    defaultValues: { listing: '' },
  })

  const options = profile.matchingsForOffer.map(({ id, listingTitle }) => ({
    id,
    label: listingTitle,
  })) as Array<SelectOption>

  const onSubmit = handleSubmit(() => onConfirm(getValues().listing))

  return (
    <Modal
      open
      title={<ModalHeader profile={profile} />}
      showClose={false}
      withBorders={false}
      handleClose={onCancel}
      content={
        <>
          <InfoBanner>
            <Paragraph size="body-sm" weight="font-normal" className="text-neutral-darkest">
              {profile.firstName} is participating in two different job application processes.
              Please select for which one do you want to make an offer.
            </Paragraph>
          </InfoBanner>
          <form className="mt-10" onSubmit={onSubmit}>
            <Label htmlFor="listing">Select for which job listing you want to make an offer:</Label>
            <StyledSelect
              name="listing"
              id="listing"
              placeholder="Select"
              options={options}
              register={register}
              setValue={setValue}
              watch={watch}
            />
            <div className="flex justify-end gap-4">
              <TertiaryButton onClick={onCancel}>Cancel</TertiaryButton>
              <PrimaryButton type="submit">Next</PrimaryButton>
            </div>
          </form>
        </>
      }
    />
  )
}
