import classNames from 'classnames'
import { ReactNode, useEffect, useState } from 'react'
import * as RadixSelect from '@radix-ui/react-select'

import { FieldError } from 'shared/components/atoms'
import { Text } from 'components/Text'

import { UseFormSetValue, UseFormWatch } from 'react-hook-form'

import { ReactComponent as ChevronUpIcon } from 'icons/chevron-up.svg'
import { ReactComponent as ChevronDownIcon } from 'icons/chevron-down.svg'

export interface SelectOption {
  id: number | string
  label: string
  icon?: React.ReactNode
  description?: string
}
interface SelectProps {
  register: (key: string) => Array<string | undefined>
  name: string
  watch: UseFormWatch<Record<string, string | number>>
  setValue: UseFormSetValue<Record<string, string | number>>
  options: Array<SelectOption>
  placeholder?: string
  id?: string
  className?: string
  size?: 'sm' | 'md'
  textSize?: 'small-body' | 'body'
  showBorder?: boolean
  showFieldError?: boolean
  children?: ReactNode
  disabled?: boolean
}

export const StyledSelect = ({
  register,
  name,
  watch,
  setValue,
  options,
  placeholder,
  id,
  className,
  size = 'md',
  textSize = 'body',
  showBorder = true,
  showFieldError = true,
  children,
  disabled = false,
}: SelectProps) => {
  const [selectedOption, setSelectedOption] = useState<SelectOption | undefined>(undefined)
  const [error] = register(name)
  const value = watch(name)

  const handleChange = (newValue: string | undefined) => {
    if (newValue) setValue(name, newValue, { shouldValidate: true, shouldDirty: true })
  }

  useEffect(() => setSelectedOption(options.find(({ id }) => id == value)), [value, options])

  return (
    <div className={classNames('w-full', className)}>
      <RadixSelect.Select
        name={name}
        value={value ? value.toString() : undefined}
        onValueChange={handleChange}
      >
        <RadixSelect.SelectTrigger
          id={id}
          className={classNames(
            'flex items-center justify-between gap-3',
            'px-4 w-full',
            'rounded-[4px] bg-neutral-day',
            'hover:border-neutral-medium transition duration-150',
            {
              'border border-neutral-light': showBorder,
              'py-1 h-8': size === 'sm',
              'py-2 h-10': size === 'md',
              'cursor-pointer': !disabled,
            },
          )}
          disabled={disabled}
        >
          <RadixSelect.Value asChild>
            <div className="w-[calc(100%-24px)]">
              {selectedOption ? (
                <div className="flex items-start justify-start gap-3">
                  {selectedOption.icon}
                  <Text
                    size={textSize}
                    className="!whitespace-nowrap overflow-hidden capitalize"
                    color={disabled ? 'text-neutral-medium' : 'text-neutral-darkest'}
                  >
                    {selectedOption.label}
                  </Text>
                </div>
              ) : (
                <Text size={textSize} color="text-neutral-medium" className="text-left">
                  {placeholder}
                </Text>
              )}
            </div>
          </RadixSelect.Value>
          <RadixSelect.Icon>
            <ChevronDownIcon
              className={classNames('w-6 h-6', {
                'stroke-neutral-medium': disabled,
                'stroke-neutral-darkest': !disabled,
              })}
            />
          </RadixSelect.Icon>
        </RadixSelect.SelectTrigger>

        {/* We need this stopPropagation to not break this component when opened on a Modal (when running on firefox) */}
        <RadixSelect.Portal onFocus={(e) => e.stopPropagation()}>
          <RadixSelect.Content className="max-w-[520px] overflow-hidden rounded-lg border border-neutral-lighter bg-white py-2 z-40 shadow-sm relative left-2">
            <RadixSelect.SelectScrollUpButton className="flex items-center justify-center bg-white h-6">
              <ChevronUpIcon className="w-6 h-6 stroke-neutral-darkest" />
            </RadixSelect.SelectScrollUpButton>
            <RadixSelect.Viewport className="p-[1px]">
              {options.map(({ id, label, icon, description }) => {
                return (
                  <RadixSelect.Item
                    key={id}
                    value={id.toString()}
                    className="px-6 py-2 hover:bg-neutral-lightest cursor-pointer select-none"
                  >
                    <RadixSelect.ItemText>
                      <div className="flex items-start justify-start gap-3">
                        {icon}
                        <div className="flex flex-col items-stretch justify-start gap-2">
                          <Text size={textSize} className="w-fit capitalize">
                            {label}
                          </Text>
                          {description && (
                            <Text size="caption" color="text-neutral-dark">
                              {description}
                            </Text>
                          )}
                        </div>
                      </div>
                    </RadixSelect.ItemText>
                  </RadixSelect.Item>
                )
              })}
            </RadixSelect.Viewport>
            <RadixSelect.SelectScrollDownButton className="flex items-center justify-center bg-white h-6">
              <ChevronDownIcon className="w-6 h-6 stroke-neutral-darkest" />
            </RadixSelect.SelectScrollDownButton>
          </RadixSelect.Content>
        </RadixSelect.Portal>
      </RadixSelect.Select>

      {children}
      {showFieldError && <FieldError message={error} />}
    </div>
  )
}
