import { usePendingSupplementalTerms } from './usePendingSupplementalTerms'
import { TermsOfService } from 'onboarding/exports'
import { useEffect, useState } from 'react'
import { useAuth } from 'shared/hooks'
import { SupplementalTermsFeature } from 'modules/supplemental-terms/enums'
import { useAcceptSupplementalTerms } from 'onboarding/hooks/useAcceptSupplementalTerms'

interface PendingSupplementalTermsCheckerProps {
  feature: SupplementalTermsFeature
}

export const PendingSupplementalTermsChecker = ({
  feature,
}: PendingSupplementalTermsCheckerProps) => {
  const { isLoaded, user } = useAuth()
  const [visible, setVisible] = useState(false)
  const [supplementalTerms] = usePendingSupplementalTerms(feature)
  const { acceptTerms } = useAcceptSupplementalTerms(feature)

  useEffect(() => {
    setVisible(Boolean(supplementalTerms))
  }, [supplementalTerms])

  const handleAcceptance = () => acceptTerms(supplementalTerms!).then(() => setVisible(false))

  const titles = {
    [SupplementalTermsFeature.Refer]: 'Refer Terms',
    [SupplementalTermsFeature.ClientReferral]: 'Referral Terms',
  }

  if (visible && isLoaded && user.role) {
    return (
      <TermsOfService
        title={titles[feature]}
        agreement={supplementalTerms!}
        onAccept={handleAcceptance}
        redirectUrl="/"
      />
    )
  }

  return null
}
