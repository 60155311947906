import { ChangeLogType, MatchingEventFilter, ProfileEventFilter } from 'candidates-search/enums'

export const matchingEvents: Record<MatchingEventFilter, Array<ChangeLogType>> = {
  [MatchingEventFilter.MatchingUpdates]: [
    ChangeLogType.MatchingUpserted,
    ChangeLogType.MatchingFlagsUpserted,
    ChangeLogType.OpportunityUpserted,
    ChangeLogType.OpportunityDeleted,
    ChangeLogType.LeadDeleted,
    ChangeLogType.LeadUpserted,
  ],
  [MatchingEventFilter.ScreeningProgress]: [
    ChangeLogType.ScreeningAssigned,
    ChangeLogType.ScreeningApplied,
    ChangeLogType.ScreeningMissed,
    ChangeLogType.ScreeningViewed,
    ChangeLogType.ScreeningStarted,
    ChangeLogType.ScreeningDeclined,
    ChangeLogType.ScreeningAnswered,
    ChangeLogType.ScreeningSubmitted,
    ChangeLogType.ScreeningApproved,
    ChangeLogType.ScreeningRejected,
  ],
  [MatchingEventFilter.CommunicationSent]: [
    ChangeLogType.ScreeningAssignedMailed,
    ChangeLogType.ScreeningMissedMailed,
    ChangeLogType.ScreeningAwaitingReviewMailed,
    ChangeLogType.ScreeningRejectedMailed,
    ChangeLogType.JobListingPausedMailed,
    ChangeLogType.MatchingRejectedMailed,
  ],
}

export const profileEvents: Record<ProfileEventFilter, Array<ChangeLogType>> = {
  [ProfileEventFilter.ProfileUpdates]: [
    ChangeLogType.ProfileCreated,
    ChangeLogType.ProfileCompleted,
    ChangeLogType.ProfileUpdatesAcknowledged,
    ChangeLogType.ProfileFulfillmentStatusUpdated,
  ],
  [ProfileEventFilter.AssessmentTags]: [ChangeLogType.ProfileAssessmentTagsUpserted],
  [ProfileEventFilter.Proficiencies]: [ChangeLogType.ProfileProficienciesUpserted],
  [ProfileEventFilter.RolesOfInterest]: [ChangeLogType.ProfileInterestRolesUpserted],
  [ProfileEventFilter.MainSkills]: [ChangeLogType.ProfileMainSkillsUpserted],
  [ProfileEventFilter.WorkExperience]: [
    ChangeLogType.ProfileWorkExperienceUpserted,
    ChangeLogType.ProfileWorkExperienceDeleted,
  ],
  [ProfileEventFilter.PayRate]: [ChangeLogType.ProfilePayRateUpserted],
  [ProfileEventFilter.EnglishCheck]: [
    ChangeLogType.EnglishPackageSubmitted,
    ChangeLogType.EnglishPackageEvaluationUpserted,
  ],
  [ProfileEventFilter.STA]: [
    ChangeLogType.ProfileAssessmentEnabled,
    ChangeLogType.ProfileAssessmentSubmitted,
    ChangeLogType.ProfileAssessmentEvaluated,
  ],
  [ProfileEventFilter.Education]: [
    ChangeLogType.ProfileEducationUpserted,
    ChangeLogType.ProfileEducationDeleted,
  ],
  [ProfileEventFilter.Certification]: [
    ChangeLogType.ProfileCertificationUpserted,
    ChangeLogType.ProfileCertificationDeleted,
  ],
  [ProfileEventFilter.Bio]: [ChangeLogType.ProfileBioUpserted],
  [ProfileEventFilter.PersonalInfo]: [ChangeLogType.ProfilePersonalInfoUpserted],
  [ProfileEventFilter.Preferences]: [ChangeLogType.ProfilePreferencesUpserted],
}
