import { useQuery } from 'react-query'
import { useNetwork } from 'shared/hooks'

export const useFetchNewScreeningAssignmentReport = (listingId: string) => {
  const { get } = useNetwork()

  const { data: report = { count: 0 }, isLoading } = useQuery(['matchings-stats', listingId], () =>
    get<{ count: number }>(
      `listings/${listingId}/matchings/reports/new-screening-assignment/counts`,
    ).then(({ data }) => data),
  )

  return { report, isLoading }
}
