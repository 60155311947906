import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { StepsWizard } from 'shared/components/atoms'
import { AccountType, SwitchToLoginLink, SidePanel } from 'authentication/components'

import { Roles } from 'account/enums'
import { useToast } from 'shared/hooks'

import { SignUpStep } from './SignUpStep'

const SignUpPage = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const redirectUrl = urlParams.get('redirectUrl') || ''
  const navigate = useNavigate()
  const [accountRole, setAccountRole] = useState<Roles>()
  const { toastError } = useToast()

  const calculateInitialStep = () =>
    [
      '/job-seeker-onboarding',
      '/employer-onboarding',
      '/listings/create?onboarding=1',
      '/job-seeker-onboarding?referral=',
    ].some((url) => redirectUrl.includes(url.toLocaleLowerCase()))
      ? 2
      : 1

  const handleAccountTypeSelected = (accountRole: Roles, nextStep?: () => void) => {
    setAccountRole(accountRole)
    nextStep!()

    if (accountRole === Roles.JobSeeker) {
      return navigate('/signup?redirectUrl=/job-seeker-onboarding')
    }

    if (accountRole === Roles.EmployerOwner) {
      return navigate('/signup?redirectUrl=/employer-onboarding')
    }

    if (accountRole === Roles.Recruiter) {
      return navigate('/signup?redirectUrl=/recruiter-onboarding')
    }

    toastError('Invalid account type')
  }

  return (
    <div className="flex flex-col-reverse lg:flex-row min-h-screen">
      <main className="flex flex-col flex-grow justify-center items-center bg-white">
        <StepsWizard isLazyMount initialStep={calculateInitialStep()}>
          <AccountType
            footer={<SwitchToLoginLink />}
            onAccountTypeSelected={handleAccountTypeSelected}
          />
          <SignUpStep redirectUrl={redirectUrl} />
        </StepsWizard>
      </main>
      <SidePanel accountRole={accountRole} />
    </div>
  )
}

export { SignUpPage }
