import { useState } from 'react'

const STEPPER_DISMISSED_KEY = 'dismiss_task_stepper'

export const useDismissed = () => {
  const initialState = JSON.parse(localStorage.getItem(STEPPER_DISMISSED_KEY) || 'false')
  const [isDismissed, setIsDismissed] = useState(initialState)

  const setDismissed = () => {
    localStorage.setItem(STEPPER_DISMISSED_KEY, 'true')
    setIsDismissed(true)
  }

  return { setDismissed, isDismissed }
}
