import { useState } from 'react'

import { Title, Paragraph } from 'shared/components/atoms'

import { ReactComponent as Logo } from 'icons/brand/brand-colored-positive.svg'
import { ReactComponent as BuildingIcon } from 'icons/building-05-outlined.svg'
import { ReactComponent as ShareIcon } from 'icons/share-07.svg'
import { ReactComponent as UserIcon } from 'icons/user-plus-02.svg'

import { Option } from '.'
import { Roles } from 'account/enums'

interface Props {
  footer: React.ReactNode
  onAccountTypeSelected: (accountRole: Roles, nextStep?: () => void) => void
  nextStep?: () => void
}

export const AccountType = ({ footer, onAccountTypeSelected, nextStep }: Props) => {
  const [selectedRole, setSelectedRole] = useState<Roles>()

  const selectOnboarding = (accountRole: Roles) => {
    setSelectedRole(accountRole)
    onAccountTypeSelected(accountRole, nextStep)
  }

  return (
    <div className="flex w-full justify-center items-center px-4 lg:px-0">
      <div className="flex w-full max-w-[480px] flex-col gap-14">
        <Logo className="h-7 w-auto hidden lg:flex lg:mt-16 z-10" />

        <div className="flex flex-col gap-2 items-center">
          <Title size="heading">I am here to</Title>
          <Paragraph size="body-sm" weight="font-normal">
            Choose an option below to create an account
          </Paragraph>
        </div>

        <div className="flex flex-col gap-4 w-full">
          <Option
            isSelected={selectedRole === Roles.JobSeeker}
            icon={<UserIcon className="w-6 h-6 stroke-neutral-darker" />}
            title="Get a job"
            description="I'm a job seeker and want to find opportunities."
            onClick={() => selectOnboarding(Roles.JobSeeker)}
          />
          <Option
            isSelected={selectedRole === Roles.EmployerOwner}
            icon={<BuildingIcon className="w-6 h-6 stroke-neutral-darker" />}
            title="Hire developers"
            description="I'm an employer and want to hire talent."
            onClick={() => selectOnboarding(Roles.EmployerOwner)}
          />

          <Option
            isSelected={selectedRole === Roles.Recruiter}
            icon={<ShareIcon className="w-6 h-6 stroke-neutral-darker" />}
            title="Refer talent"
            description="I'm a recruiter and want to refer job seekers."
            onClick={() => selectOnboarding(Roles.Recruiter)}
          />
        </div>

        {footer}
      </div>
    </div>
  )
}
