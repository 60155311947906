import { SignUp as ClerkSignUpWidget } from '@clerk/clerk-react'

import { signUpAppearance } from 'authentication/consts'

interface SignUpFormProps {
  redirectUrl: string | null
  centralized?: boolean
}

export const SignUpForm = ({ redirectUrl, centralized = false }: SignUpFormProps) => {
  return (
    <ClerkSignUpWidget
      signInUrl="/login"
      redirectUrl={redirectUrl}
      appearance={signUpAppearance(centralized)}
    />
  )
}
