import { useQuery } from 'react-query'
import { AccountClaim, useAuth, useNetwork } from 'shared/hooks'

export const useMatchingsReports = (listingId: string) => {
  const { get } = useNetwork()
  const {
    user: { claims },
  } = useAuth()

  const { data: report = { count: 0 }, isLoading } = useQuery(
    ['reports-listing-paused-email-counts', listingId],
    () =>
      get<{ count: number }>(
        `listings/${listingId}/matchings/reports/listing-paused-email/counts`,
      ).then(({ data }) => data),
    {
      enabled: claims.includes(AccountClaim.PauseJobListings),
    },
  )

  return { report, isLoading }
}
